import '../common/assets/scss/index.scss';
import React, {useEffect, useState} from 'react';
import {BuilderRootState, builderStore} from './builderStore';
import {Provider} from 'react-redux';
import Editor from "./components/Editor";
import {builderActions} from "./slices/builderSlice";
import {ActionCreators} from "redux-undo";
import initPageSync from "./util/redux/initPageSync";
import syncUrlParams from "../common/util/syncUrlParams";
import {editorActions} from "./slices/editorSlice";
import PageComponent from "./components/element/Page";
import isServerActive from "../common/util/isServerActive";
import createPageNormalized from "./factories/createPageNormalized";
import request from "../common/util/request";
import Page from "./types/Page";
import setUrlParam from "../common/util/setUrlParam";

type Props = {
    pageId: string
    editable?: boolean
}

export default function Builder({pageId: propsPageId, editable}: Props) {
    const [pageId, setPageId] = useState<string>();

    useEffect(() => {
        if (editable) {
            return syncUrlParams<BuilderRootState>(builderStore, {
                field: [state => state.undoable.present.editor.activeFieldId, editorActions.setActiveField],
                group: [state => state.undoable.present.editor.activeGroupId, editorActions.setActiveGroup],
                panel: [state => state.undoable.present.editor.panel,
                    val => editorActions.setPanel(val === 'left' || val === 'right' ? val : undefined)
                ]
            });
        }
    }, [editable])

    useEffect(() => {
        (async () => {
            let page: Page | undefined;

            if (propsPageId && isServerActive()) {
                page = await request<Page>('GET', '/page/' + propsPageId + '.json');

            } else if (!propsPageId && isServerActive()) {
                page = createPageNormalized();
                await request<Page>('POST', '/page.json', page);
                setUrlParam('page', page.id);
            } else {
                page = createPageNormalized();
            }

            if (!page) {
                return;
            }

            builderStore.dispatch(builderActions.init(page));
            setPageId(page.id);
            builderStore.dispatch(ActionCreators.clearHistory());
        })();
    }, [propsPageId, editable]);

    useEffect(() => {
        if (pageId && isServerActive()) {
            return initPageSync(builderStore, pageId);
        }
    }, [pageId]);

    if (!pageId) {
        return <div>loading...</div>;
    }

    return (
        <Provider store={builderStore}>
            {editable ? <Editor pageId={pageId}/> : <PageComponent pageId={pageId}/>}
        </Provider>
    )
}

