import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import Data from "../types/data/Data";

const initialState: Data = {
    submitting: false,
    validated: false,
    values: {},
    errors: {}
};

const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        startSubmit: (state) => {
            state.submitting = true;
        },
        endSubmit: (state, {payload: errors}: PayloadAction<Data['errors'] | undefined>) => {
            state.submitting = false;
            state.errors = errors || {};
            state.validated = true;
        },
        setValidated: (state, {payload: validated}: PayloadAction<boolean>) => {
            state.validated = validated;
        },
        setValue: (state, {payload}: PayloadAction<{ fieldId: string, value: string }>) => {
            const {fieldId, value} = payload;
            state.values[fieldId] = value;
        },
    }
});

export const dataReducer = dataSlice.reducer;
export const dataActions = dataSlice.actions;

