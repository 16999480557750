import {createAsyncThunk} from "@reduxjs/toolkit";
import request from "../../common/util/request";
import Data from "../../common/types/Data";
import StyleSet from "../types/StyleSet";
import StyleSetRefs from "../types/StyleSetRefs";
import ColorSet from "../../colorer/types/ColorSet";
import isServerActive from "../../common/util/isServerActive";
import createColorSet from "../../colorer/factories/createColorSet";
import createColor from "../../colorer/factories/createColor";
import Font from "../types/Font";
import createFont from "../factories/createFont";

const fetchStyleSets = createAsyncThunk(
    'fetchStyleSets',
    async () => {
        let result: Data<StyleSet[], StyleSetRefs> | undefined;
        let defaultColorSet: ColorSet | undefined;
        let fonts: Font[] | undefined;

        if (isServerActive()) {
            result = await request<Data<StyleSet[], StyleSetRefs>>('GET', '/styles.json');
            defaultColorSet = await request<ColorSet>('GET', '/colors/default.json');
            fonts = await request<Font[]>('GET', '/styles/fonts.json');
        } else {
            result = {"@data": [], "@refs": {colorSets: {}, fonts: {}}};
            defaultColorSet = createColorSet('Default', true, [
                createColor('Black', '#000000')
            ]);
            fonts = [createFont('Helvetica Neue')];
        }

        if (!result || !defaultColorSet || !fonts) {
            return undefined;
        }

        return {
            styleSets: result["@data"],
            styleSetRefs: result['@refs'],
            defaultColorSet,
            fonts
        }
    }
);

export default fetchStyleSets;