import React from "react";
import Type from "../../../types/element/fields/TextNormalizedEntry";
import useFieldProp from "../../../hooks/element/useFieldProp";
import Rte from "../../../../common/components/rte/Rte";

type Props = {
    fieldId: string
}

function TextEditable({fieldId}: Props) {
    const [content, setContent] = useFieldProp<Type>({fieldId, property: 'content'})

    return (
        <Rte value={content} onChange={setContent}/>
    );
}

export default React.memo(TextEditable);