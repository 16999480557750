import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import HeadlineNormalizedEntry from "../../types/element/fields/HeadlineNormalizedEntry";

type Payload = {
    level: 1 | 2 | 3,
    content: string,
    width?: number
}

export default function createHeadline({content, level, width = COL_WIDTH}: Payload): HeadlineNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'headline',
        ...baseField,
        content,
        level
    }
}