import useBuilderSelector from "../useBuilderSelector";
import {editorActions} from "../../slices/editorSlice";
import useBuilderDispatch from "../useBuilderDispatch";
import {BuilderRootState} from "../../builderStore";
import {useCallback} from "react";

const activeSelector = (state: BuilderRootState, fieldId: string) => state.undoable.present.editor.activeFieldId === fieldId;

export default function useFieldActive(fieldId: string): [boolean, (active: boolean) => void] {
    const dispatch = useBuilderDispatch();
    const active = useBuilderSelector(state => activeSelector(state, fieldId));

    const setActive = useCallback((active: boolean) => {
        dispatch((dispatch, getState) => {
            const currentActive = activeSelector(getState(), fieldId);

            if (currentActive !== active) {
                dispatch(editorActions.setActiveField(active ? fieldId : undefined));
            }
        });
    }, [dispatch, fieldId]);

    return [active, setActive];
}