import PageNormalized from "../../types/element/BuilderState";
import arrayRemove from "../../../common/util/arrayRemove";
import removeContainer from "./removeContainer";
import removeRow from "./removeRow";

export default function removeGroup(pageNormalized: PageNormalized, groupId: string, keepReference?: boolean) {
    const group = pageNormalized.groups[groupId];
    const container = pageNormalized.containers[group?.containerId || ''];

    if (!group) return;

    group.containerId = undefined;
    arrayRemove(container?.groupIds || [], groupId);

    if (!keepReference) {
        delete pageNormalized.groups[groupId]
        group.rowIds.forEach(rowId => removeRow(pageNormalized, rowId));
    }

    //remove the container if there is no group inside
    if (container && !container.groupIds.length) {
        removeContainer(pageNormalized, container.id);
    }
}