import {DraftBlockType, DraftInlineStyleType, EditorState} from "draft-js";
import RteStyleToggle from "./toolbar/RteStyleToggle";
import {faBold, faItalic, faListOl, faListUl, faUnderline} from "@fortawesome/free-solid-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import RteBlockToggle from "./toolbar/RteBlockToggle";
import {Overlay, Tooltip} from "react-bootstrap";
import {RefObject, useContext, useEffect, useRef} from "react";
import getSelectionRect from "../../util/getSelectionRect";
import styles from "./RteToolbar.module.scss";
import {EditorContext} from "../../../builder/components/Editor";

type Props = {
    editorRef: RefObject<HTMLDivElement>
    editorState: EditorState
    onChange: (editorState: EditorState) => void
}

const INLINE_STYLES: { style: DraftInlineStyleType, icon: IconProp, title: string }[] = [
    {style: 'BOLD', icon: faBold, title: 'bold'},
    {style: 'ITALIC', title: 'Italic', icon: faItalic},
    {style: 'UNDERLINE', title: 'Underline', icon: faUnderline},
]

const BLOCK_TYPES: { blockType: DraftBlockType, icon: IconProp, title: string }[] = [
    {blockType: "ordered-list-item", icon: faListOl, title: "OL List"},
    {blockType: "unordered-list-item", icon: faListUl, title: "UL List"},
]

export default function RteToolbar({editorState, onChange, editorRef}: Props) {
    const contentRef = useContext(EditorContext);
    const tooltip = useRef<HTMLDivElement>(null);
    const target = useRef(null);

    const editorNode = editorRef.current;

    useEffect(() => {
        if (!editorNode) {
            return;
        }

        const onClick = (e: MouseEvent) => {
            const tooltipNode = tooltip.current;

            if (!tooltipNode) {
                return;
            }

            const rect = getSelectionRect();
            const force = e.altKey || e.ctrlKey || e.metaKey;

            if (!rect || (!force && !rect.width)) {
                tooltipNode.style.visibility = 'hidden';
                return;
            }

            const left = rect.left - tooltipNode.clientWidth / 2 + rect.width / 2;
            const top = rect.top - tooltipNode.clientHeight;

            tooltipNode.style.transform = `translate(${left}px, ${top}px)`;
            tooltipNode.style.visibility = 'visible';
        }

        const onKeyDown = () => {
            const tooltipNode = tooltip.current;
            if (!tooltipNode) {
                return;
            }

            tooltipNode.style.visibility = 'hidden';
        }

        editorNode.addEventListener('click', onClick);
        editorNode.addEventListener('keydown', onKeyDown);

        return () => {
            editorNode.removeEventListener('click', onClick);
            editorNode.removeEventListener('keydown', onKeyDown);
        }
    }, [editorNode, tooltip]);

    return (
        <Overlay show={true} placement="top" target={target} ref={tooltip} container={contentRef}>
            <Tooltip className={styles.tooltip} placement="top">
                {INLINE_STYLES.map(({style, icon, title}) => (
                    <RteStyleToggle key={style} style={style}
                                    editorState={editorState} onChange={onChange}
                                    title={title}
                                    icon={icon}
                    />
                ))}
                {BLOCK_TYPES.map(({blockType, icon, title}) => (
                    <RteBlockToggle key={blockType} blockType={blockType}
                                    editorState={editorState} onChange={onChange}
                                    title={title}
                                    icon={icon}
                    />
                ))}
            </Tooltip>
        </Overlay>
    );
}