import ColorSet from "../types/ColorSet";
import {ColorSetNormalized} from "./normalizeColorSet";
import denormalizeColor from "./denormalizeColor";

export default function denormalizeColorSet(normalized: ColorSetNormalized, colorSetId: string, position: number): ColorSet | undefined {
    const colorSetNormalized = normalized.colorSets[colorSetId];

    if (!colorSetNormalized) {
        console.error('ColorSet could not be denormalized. ColorSet not found:', {colorSetId, normalized})
        return undefined;
    }

    const {colorIds, ...rest} = colorSetNormalized;

    return {
        ...rest,
        position,
        colors: colorIds.flatMap((colorId, i) => {
            const color = denormalizeColor(normalized, colorId, i);

            return color ? [color] : [];
        })
    }

}