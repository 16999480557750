import BaseTextStyleToggle from "./BaseTextStyleToggle";
import {faBold, faItalic, faUnderline} from "@fortawesome/free-solid-svg-icons";

type Props = {
    styleType: 'text' | 'headline'
    styleId: string
}

export default function LinkHoverStyleToggles({styleId, styleType}: Props) {
    return (
        <>
            <label className="form-label small">Link-Hover-Stil</label>
            <div className="d-flex">
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkHoverBold" icon={faBold}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkHoverItalic" icon={faItalic}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkHoverUnderline" icon={faUnderline}/>
            </div>
        </>
    );
}