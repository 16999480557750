import React from 'react';
import {Provider} from 'react-redux';
import colorerStore from "../colorerStore";
import ColorSets from "./ColorSets";

export default function Colorer() {
    return (
        <Provider store={colorerStore}>
            <ColorSets/>
        </Provider>
    )
}