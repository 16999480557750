import {v4 as uuid} from "uuid";
import createHeadlineStyle from "./styles/createHeadlineStyle";
import createTextStyle from "./styles/createTextStyle";
import createOrderedListStyle from "./styles/createOrderedListStyle";
import createUnorderedListStyle from "./styles/createUnorderedListStyle";
import StyleSet from "../types/StyleSet";
import Reference from "../../common/types/Reference";

export default function createStyleSet(name: string, isDefault: boolean, colorSet: Reference, font: Reference): StyleSet {
    return {
        id: uuid(),
        name,
        default: isDefault,
        colorSet: colorSet,
        headline1Style: createHeadlineStyle(font),
        headline2Style: createHeadlineStyle(font),
        headline3Style: createHeadlineStyle(font),
        headline4Style: createHeadlineStyle(font),
        headline5Style: createHeadlineStyle(font),
        text1Style: createTextStyle(font),
        text2Style: createTextStyle(font),
        text3Style: createTextStyle(font),
        orderedListStyle: createOrderedListStyle(),
        unorderedListStyle: createUnorderedListStyle(),
    };
}
