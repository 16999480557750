import {configureStore} from '@reduxjs/toolkit';
import {colorerReducer} from "./colorerSlice";

const colorerStore = configureStore({
    reducer: colorerReducer,
    devTools: process.env.NODE_ENV === 'development' ? {
        name: 'Colorer Store',
    } : false
});

export default colorerStore;
export type ColorerStore = typeof colorerStore;
export type ColorerDispatch = typeof colorerStore.dispatch;
export type ColorerRootState = ReturnType<typeof colorerStore.getState>;