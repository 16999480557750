import {useEffect} from 'react';

export default function useDebounce(value: any, operation: (value: any, ...params: any[]) => void, delay = 400, ...params: any[]): void {
    useEffect(() => {
        const handler = setTimeout(() => {
            clearTimeout(handler);
            operation(value, ...params);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [value, operation, delay, params]);
}