import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import SubmitButtonNormalizedEntry from "../../types/element/fields/SubmitButtonNormalizedEntry";

type Payload = {
    label: string,
    width?: number
}

export default function createSubmitButton({label, width = COL_WIDTH}: Payload): SubmitButtonNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'submit-button',
        ...baseField,
        label
    }
}