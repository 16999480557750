import BuilderState from "../types/element/BuilderState";

export default function createBuilderState(): BuilderState {
    return {
        rows: {},
        containers: {},
        fields: {},
        groups: {},
        pages: {}
    }
}