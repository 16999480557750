import React from "react";
import {COL_WIDTH} from "../../../../config";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import getGroupByFieldId from "../../util/element/getGroupByFieldId";
import calsFieldMaxWidth from "../../util/element/calsFieldMaxWidth";
import useColResize from "../../hooks/useColResize";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {builderActions} from "../../slices/builderSlice";
import {dragActions} from "../../slices/dragSlice";
import getElementsByRootState from "../../util/element/getElementsByRootState";
import ResizeButton from "../../../common/components/ResizeButton";
import getFieldWidth from "../../util/element/getFieldWidth";

type Props = {
    fieldId: string,
    fieldRef: React.RefObject<HTMLDivElement>
}

function FieldResizeButton({fieldId, fieldRef}: Props) {
    const dispatch = useBuilderDispatch();
    const width = useElementsSelector(elements => elements.fields[fieldId]?.width || COL_WIDTH);
    const min = useElementsSelector(elements => getFieldWidth(elements, fieldId));
    const groupWidth = useElementsSelector(elements => getGroupByFieldId(elements, fieldId)?.width || 0);
    const widthLeft = useElementsSelector(elements => calsFieldMaxWidth(elements, fieldId));
    const {listeners} = useColResize({
        ref: fieldRef,
        min: min,
        max: width + widthLeft,
        width: width,
        cols: groupWidth,
        onResizeStart: () => dispatch((dispatch, getState) => {
            dispatch(dragActions.start({elements: getElementsByRootState(getState())}));
        }),
        onResize: (width) => {
            dispatch(builderActions.resizeField({fieldId, width, silent: true}));
        },
        onResizeEnd: width => {
            dispatch((dispatch, getState) => {
                const state = getState().drag.elements;

                dispatch(dragActions.end());
                state && dispatch(builderActions.revert({state, silent: true}));
                dispatch(builderActions.resizeField({fieldId, width}));
            })
        }
    });

    if (widthLeft === 0 && width === 1) {
        return null;
    }

    return (
        <ResizeButton {...listeners} position="right" color="lightblue"/>
    );
}

export default React.memo(FieldResizeButton);