import React from "react";
import useElementDroppable from "../../hooks/element/useElementDragable";
import FieldFixture from "./FieldFixture";

type Props = {
    fixtureId: string
}

export default function DragFieldFixture({fixtureId}: Props) {
    const {attributes, listeners, setNodeRef} = useElementDroppable({type: 'fixture', fixtureId});

    return (
        <div ref={setNodeRef} {...listeners} {...attributes}>
            <FieldFixture fixtureId={fixtureId}/>
        </div>
    );
}