import FontSelect from "./FontSelect";
import FontSizeForm from "./FontSizeForm";
import LineHeightForm from "./LineHeightForm";
import LetterSpacingForm from "./LetterSpacingForm";
import AlignSelect from "./AlignSelect";
import TextStyleToggles from "./TextStyleToggles";
import LinkHoverStyleToggles from "./LinkHoverStyleToggles";
import LinkStyleToggles from "./LinkStyleToggles";
import BaseTextColorSelect from "./BaseTextColorSelect";

type Props = {
    styleType: 'text' | 'headline'
    styleId: string
}

export default function TextStyleForm({styleId, styleType}: Props) {
    return (
        <div>
            <div className="mb-2">
                <BaseTextColorSelect label="Schriftfarbe" styleType={styleType} styleProp="colorIndex"
                                     styleId={styleId}/>
            </div>
            <div className="mb-2">
                <FontSelect styleType={styleType} styleId={styleId}/>
            </div>
            <div className="row mb-2">
                <div className="col-6">
                    <FontSizeForm styleType={styleType} styleId={styleId}/>
                </div>
                <div className="col-6">
                    <LetterSpacingForm styleType={styleType} styleId={styleId}/>
                </div>
            </div>
            <div className="mb-2 row">
                <div className="col-6">
                    <LineHeightForm styleType={styleType} styleId={styleId}/>
                </div>
            </div>
            <div className="mb-2">
                <TextStyleToggles styleType={styleType} styleId={styleId}/>
            </div>
            <div className="mb-2">
                <AlignSelect styleType={styleType} styleId={styleId}/>
            </div>
            <div className="mb-2">
                <BaseTextColorSelect label="Link-Farbe" styleType={styleType} styleProp="linkColorIndex"
                                     styleId={styleId}/>
            </div>
            <div className="mb-2">
                <LinkStyleToggles styleType={styleType} styleId={styleId}/>
            </div>
            <div className="mb-2">
                <BaseTextColorSelect label="Link-Hover-Farbe" styleType={styleType} styleProp="linkHoverColorIndex"
                                     styleId={styleId}/>
            </div>
            <div className="mb-2">
                <LinkHoverStyleToggles styleType={styleType} styleId={styleId}/>
            </div>
        </div>
    );
}