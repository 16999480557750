import React from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/HeadlineNormalizedEntry";
import HeadlineEditable from "./HeadlineEditable";

type Props = {
    fieldId: string
    editable?: boolean
}

function Headline({fieldId, editable}: Props) {
    const content = useFieldSelector<Type>(fieldId, field => field?.content);
    const level = useFieldSelector<Type>(fieldId, field => field?.level);

    if (editable) {
        return <HeadlineEditable fieldId={fieldId}/>;
    }

    switch (level) {
        case 1:
            return <h1 className="break-word" dangerouslySetInnerHTML={{__html: content}}/>
        case 2:
            return <h2 className="break-word" dangerouslySetInnerHTML={{__html: content}}/>
        case 3:
            return <h3 className="break-word" dangerouslySetInnerHTML={{__html: content}}/>
        default:
            return null;
    }
}

export default React.memo(Headline);