import normalizeTextStyle from "./normalizeTextStyle";
import TextStyle from "../../types/styles/TextStyle";
import createReference from "../../../common/util/createReference";

export default function denormalizeTextStyle(normalized: ReturnType<typeof normalizeTextStyle>, styleId: string): TextStyle | undefined {
    const style = normalized.styles[styleId];
    if (!style) return undefined;

    const {fontId, ...rest} = style;
    return {...rest, font: createReference(fontId)}
}