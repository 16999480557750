import useDesignerSelector from "../hooks/useDesignerSelector";

import getActiveStyleId from "../util/getActiveStyleId";
import TextStyleForm from "./elements/TextStyleForm";

export default function DesignElementForm() {
    const styleId = useDesignerSelector(getActiveStyleId);
    const type = useDesignerSelector(state => state.activeStyleType);

    if (!styleId) {
        return null;
    }

    switch (type) {
        case "text1":
        case "text2":
        case "text3":
            return <TextStyleForm styleType="text" styleId={styleId}/>
        case "headline1":
        case "headline2":
        case "headline3":
        case "headline4":
        case "headline5":
            return <TextStyleForm styleType="headline" styleId={styleId}/>
    }

    return (
        <div>{type}</div>
    );
}