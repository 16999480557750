import normalizeStyleSets from "../normalizer/normalizeStyleSets";

export default function setDefaultStyleSet(styleSetsNormalized: ReturnType<typeof normalizeStyleSets>, styleSetId: string | undefined) {
    if (styleSetId) {
        styleSetsNormalized.styleSetIds.forEach(styleId => {
            const style = styleSetsNormalized.styleSets[styleId];
            if (style) style.default = false;
        });
    }
    const style = styleSetsNormalized.styleSets[styleSetId || styleSetsNormalized.styleSetIds[0]]
    if (style) style.default = true;
}