import BuilderState from "../../types/element/BuilderState";
import {v4 as uuid} from "uuid";
import createBuilderState from "../../factories/createBuilderState";
import FieldNormalizedEntry from "../../types/element/fields/FieldNormalizedEntry";

export default function createFieldCopy(elements: BuilderState, fieldId: string): [FieldNormalizedEntry | undefined, BuilderState] {
    const field = elements.fields[fieldId];

    const references = createBuilderState();

    if (!field) {
        return [undefined, references];
    }

    const copyField = {...field, id: uuid(), rowId: undefined};
    references.fields[copyField.id] = copyField;

    return [copyField, references];
}