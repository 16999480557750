import BuilderState from "../../types/element/BuilderState";

export default function appendElements(elements: BuilderState, additional: BuilderState) {
    Object.keys(elements).forEach(key => {
        // @ts-ignore
        Object.keys(additional[key]).forEach(id => {
            // @ts-ignore
            const element = additional[key][id];
            if (element) { // @ts-ignore
                elements[key][id] = element;
            }
        });
    })
}