import React from "react";
import calcRowLeftWidth from "../../util/element/calcRowLeftWidth";
import getPercent from "../../../common/util/getPercent";
import getGroupByRowId from "../../util/element/getGroupByRowId";
import useElementDroppable from "../../hooks/element/useElementDroppable";
import useBuilderSelector from "../../hooks/useBuilderSelector";
import getElementsByRootState from "../../util/element/getElementsByRootState";

type Props = {
    rowId: string
}

function RowDropzone({rowId}: Props) {
    const {setNodeRef, isOver} = useElementDroppable({type: 'row', rowId});

    const width = useBuilderSelector(state => {
        const elements = getElementsByRootState(state);
        const source = state.drag.source;

        if (!source || source.type === 'group') {
            return 0;
        }

        const leftWidth = calcRowLeftWidth(elements, rowId);
        return leftWidth ? getPercent(leftWidth, getGroupByRowId(elements, rowId)?.width || 0) : 0;
    });

    if (!width) {
        return null;
    }

    return (
        <div style={{width}}>
            <div
                ref={setNodeRef}
                style={{opacity: isOver ? 1 : 0.5, minHeight: 20}}
                className={"d-flex border text-center h-100 align-items-center justify-content-center" + (isOver ? ' droppable' : '')}>
                <div className="rounded-3 m-2 d-inline-block pe-4 ps-4">
                    drop field here
                </div>
            </div>
        </div>
    );
}

export default React.memo(RowDropzone);