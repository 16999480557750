import Container from "../types/Container";
import merge from "ts-deepmerge";
import normalizeGroup from "./normalizeGroup";
import undefinify from "../../common/util/undefinify";

export type ContainerNormalized = ReturnType<typeof normalizeContainer>;

export default function normalizeContainer(container: Container, pageId?: string) {
    const {groups, position, ...rest} = container;

    return merge({
        containers: {
            [container.id]: undefinify({
                ...rest,
                pageId,
                groupIds: groups.map(group => group.id)
            })
        }
    }, ...groups.map(group => normalizeGroup(group, container.id)))
}