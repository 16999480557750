import {FormEvent} from "react";

type Props = {
    name: string
    setName: (name: string) => void
    default: boolean
    setDefault: (isDefault: boolean) => void
    submit: (event: FormEvent) => void
}

export default function ColorSetForm({name, setName, default: isDefault, setDefault, submit}: Props) {
    return (
        <form onSubmit={submit}>
            <input type="text" value={name} className="form-control"
                   onChange={e => setName(e.target.value)} required/>
            <div className="form-check mt-3">
                <input onChange={e => setDefault(e.target.checked)} className="form-check-input" type="checkbox"
                       checked={isDefault} id="flexCheckDefault" required={false}/>
                <label className="form-check-label" htmlFor="flexCheckDefault">
                    Als Standard verwenden
                </label>
            </div>
        </form>
    )
}