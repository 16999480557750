import React from "react";
import {builderActions} from "../../slices/builderSlice";
import {COL_WIDTH} from "../../../../config";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import calcGroupMinWidth from "../../util/element/calcGroupMinWidth";
import calcGroupMaxWidth from "../../util/element/calcGroupMaxWidth";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import useColResize from "../../hooks/useColResize";
import {dragActions} from "../../slices/dragSlice";
import getElementsByRootState from "../../util/element/getElementsByRootState";
import ResizeButton from "../../../common/components/ResizeButton";

type Props = {
    groupId: string
    groupRef: React.RefObject<HTMLDivElement>
}

function GroupResizeButton({groupId, groupRef}: Props) {
    const dispatch = useBuilderDispatch();
    const width = useElementsSelector(elements => elements.groups[groupId]?.width || COL_WIDTH);
    const widthLeft = useElementsSelector(elements => calcGroupMaxWidth(elements, groupId));
    const minWidth = useElementsSelector(elements => calcGroupMinWidth(elements, groupId));
    const {listeners} = useColResize({
        ref: groupRef,
        min: minWidth,
        max: width + widthLeft,
        width: width,
        cols: COL_WIDTH,
        onResizeStart: () => dispatch((dispatch, getState) => {
            dispatch(dragActions.start({elements: getElementsByRootState(getState())}));
        }),
        onResize: width => {
            dispatch(builderActions.resizeGroup({groupId, width, silent: true}));
        },
        onResizeEnd: width => {
            dispatch((dispatch, getState) => {
                const state = getState().drag.elements;

                dispatch(dragActions.end())
                state && dispatch(builderActions.revert({state, silent: true}));
                dispatch(builderActions.resizeGroup({groupId, width}));
            })
        }
    });

    if (widthLeft === 0 && width <= minWidth) {
        return null;
    }

    return (
        <ResizeButton position="right" color="violet" {...listeners} />
    );
}

export default React.memo(GroupResizeButton)