import {FormEvent, useEffect, useState} from "react";
import useColorerDispatch from "../../hooks/useColorerDispatch";
import {colorerActions} from "../../colorerSlice";
import useColorerSelector from "../../hooks/useColorerSelector";
import ColorForm from "../ColorForm";
import getActiveColorId from "../../util/getActiveColorId";
import FormModal from "../../../common/components/FormModal";

export default function ColorEditModal() {
    const dispatch = useColorerDispatch();
    const colorId = useColorerSelector(getActiveColorId);
    const show = useColorerSelector(state => state.modal === 'color/edit');
    const color = useColorerSelector(state => state.colors[colorId || '']);
    const [name, setName] = useState('');
    const [hex, setHex] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        setName(color?.name || '');
        setHex(color?.hex || '');
        setDescription(color?.description || '');
    }, [color, setName, setHex, setDescription]);

    if (!show || !color || color.readonly) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name) return;

        dispatch(colorerActions.updateColor({colorId: color.id, values: {name, hex, description: description}}));
        cancel();
    }

    const cancel = () => {
        dispatch(colorerActions.closeModal());
    };

    return (
        <FormModal title="Farbe bearbeiten" cancel={cancel} submit={name && hex ? submit : undefined}>
            <ColorForm name={name} setName={setName}
                       hex={hex} setHex={setHex}
                       description={description} setDescription={setDescription}
                       submit={submit}
                       id={color.id}
            />
        </FormModal>
    )
}