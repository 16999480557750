import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const initialState: { activeFieldId?: string, activeGroupId?: string, panel?: 'left' | 'right' } = {};

const editorSlice = createSlice({
    name: 'editor',
    initialState,
    reducers: {
        setPanel: (state, {payload: panel}: PayloadAction<'right' | 'left' | undefined>) => {
            state.panel = panel;
        },
        setActiveField: (state, {payload: fieldId}: PayloadAction<string | undefined>) => {
            if(fieldId === state.activeFieldId) {
                return;
            }

            state.activeGroupId = undefined;
            state.activeFieldId = fieldId;

            if (!fieldId) {
                state.panel = undefined;
            }
        },
        setActiveGroup: (state, {payload: groupId}: PayloadAction<string | undefined>) => {
            if(groupId === state.activeGroupId) {
                return;
            }

            state.activeFieldId = undefined;
            state.activeGroupId = groupId;

            if (!groupId) {
                state.panel = undefined;
            }
        },
    }
});

export const editorReducer = editorSlice.reducer;
export const editorActions = editorSlice.actions;

