import React, {useEffect, useState} from 'react';
import colorerStore, {ColorerRootState} from "../colorerStore";
import ColorSetList from "./ColorSetList";
import {colorerActions} from "../colorerSlice";
import {ColorerModal, colorerModals} from "../types/ColorerState";
import ColorList from "./ColorList";
import ColorPreview from "./ColorPreview";
import request from "../../common/util/request";
import Sets from "../../common/components/Sets";
import ColorSetNewModal from "./modals/ColorSetNewModal";
import ColorSetEditModal from "./modals/ColorSetEditModal";
import ColorNewModal from "./modals/ColorNewModal";
import ColorEditModal from "./modals/ColorEditModal";
import denormalizeColorSets from "../normalizer/denormalizeColorSets";
import useInitColorer from "../hooks/useInitColorer";
import useColorerDispatch from "../hooks/useColorerDispatch";
import isServerActive from "../../common/util/isServerActive";
import useSyncUrlParams from "../../common/hooks/useSyncUrlParams";

type Props = {
    onClose?: () => void
    modal?: ColorerModal
}

export default function ColorSets({onClose, modal}: Props) {
    const dispatch = useColorerDispatch();
    const initialized = useInitColorer();
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (modal) {
            dispatch(colorerActions.openModal(modal));
        }
    }, [dispatch, modal])

    useSyncUrlParams<ColorerRootState>(colorerStore, {
        colorSet: [state => state.activeColorSetId, colorerActions.setActiveColorSetId],
        color: [state => state.activeColorId, colorerActions.setActiveColorId],
        colorModal: [state => state.modal, val => {
            if (val && colorerModals.includes(val as ColorerModal)) {
                return colorerActions.openModal(val as ColorerModal)
            } else {
                return colorerActions.closeModal();
            }
        }],
    });

    const save = async () => {
        if (isServerActive()) {
            setSaving(true);
            await request('PUT', '/colors.json', denormalizeColorSets(colorerStore.getState()));
            setSaving(false);
        }

        onClose && onClose();
    }

    const reset = () => {
        onClose && onClose();
        // colorsStore.dispatch(colorsActions.init(initialColorSets));
    };

    return (
        <>
            <Sets
                loading={!initialized}
                title="Farben"
                cancel={reset}
                save={save}
                saving={saving}
            >
                {[
                    {
                        title: "Set",
                        col: 4,
                        withDefault: true,
                        children: <ColorSetList/>
                    },
                    {
                        title: "Farben",
                        col: 5,
                        children: <ColorList/>
                    },
                    {
                        title: "Info & Vorschau",
                        col: 3,
                        children: <ColorPreview/>
                    }
                ]}
            </Sets>
            <ColorSetNewModal/>
            <ColorSetEditModal/>
            <ColorNewModal/>
            <ColorEditModal/>
        </>
    )
}