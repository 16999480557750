import React from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/TextNormalizedEntry";
import TextEditable from "./TextEditable";

type Props = {
    fieldId: string,
    editable?: boolean
}

function Text({fieldId, editable}: Props) {
    const content = useFieldSelector<Type>(fieldId, field => field?.content);

    if (editable) {
        return <TextEditable fieldId={fieldId}/>
    }

    return (
        <div className="break-word" dangerouslySetInnerHTML={{__html: content}}/>
    );
}

export default React.memo(Text);