import {useEffect} from "react";
import isServerActive from "../../common/util/isServerActive";
import colorerStore from "../colorerStore";
import {colorerActions} from "../colorerSlice";
import request from "../../common/util/request";
import ColorSet from "../types/ColorSet";
import useColorerSelector from "./useColorerSelector";

export default function useInitColorer() {
    const initialized = useColorerSelector(state => state.initialized);

    useEffect(() => {
        if (initialized) {
            return;
        }

        if (!isServerActive()) {
            colorerStore.dispatch(colorerActions.init([]));
            return;
        }

        (async () => {
            const colorSets = await request<ColorSet[]>('GET', '/colors.json');
            if (!colorSets) {
                return;
            }
            colorerStore.dispatch(colorerActions.init(colorSets));
        })();
    }, [initialized]);

    return initialized;
}