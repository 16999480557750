import undefinify from "../../../common/util/undefinify";
import TextStyle from "../../types/styles/TextStyle";
import fromReference from "../../../common/util/fromReference";

export default function normalizeTextStyle(style: TextStyle) {
    const {font, ...rest} = style;

    return {
        styles: {
            [style.id]: undefinify({...rest, fontId: fromReference(font)}),
        }
    };
}