import {LayoutRect, UniqueIdentifier} from "@dnd-kit/core";
import {Active, DroppableContainer} from "@dnd-kit/core/dist/store";
import {ViewRect} from "@dnd-kit/core/dist/types";
import DragSource from "../../types/drag/DragSource";

type Coordinates = { x: number, y: number }

type Args = {
    active: Active;
    collisionRect: ViewRect;
    droppableContainers: DroppableContainer[];
}

function isOverlap(entry: LayoutRect, target: ViewRect) {
    const top = Math.max(target.top, entry.offsetTop);
    const left = Math.max(target.left, entry.offsetLeft);
    const right = Math.min(
        target.left + target.width,
        entry.offsetLeft + entry.width
    );
    const bottom = Math.min(
        target.top + target.height,
        entry.offsetTop + entry.height
    );

    return left < right && top < bottom;
}

function centerOfRectangle(
    rect: LayoutRect,
    left = rect.offsetLeft,
    top = rect.offsetTop
): Coordinates {
    return {
        x: left + rect.width * 0.5,
        y: top + rect.height * 0.5,
    };
}

function distanceBetween(p1: Coordinates, p2: Coordinates) {
    return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
}

export default function closestDropzone({collisionRect, droppableContainers, active}: Args) {
    const data = active.data.current as any as DragSource;

    const collisionCoordinates: Coordinates = data.type === 'fixture' ?
        centerOfRectangle(collisionRect, collisionRect.left, collisionRect.top)
        : {x: collisionRect.left, y: collisionRect.top};

    let minDistanceToCenter = Infinity;
    let minDroppableContainer: UniqueIdentifier | null = null;

    for (const droppableContainer of droppableContainers) {
        const {
            rect: {current: rect},
        } = droppableContainer;

        if (rect) {
            if (!droppableContainer.data.current) {//dead dropzone
                if (isOverlap(rect, collisionRect)) {
                    return droppableContainer.id;
                }
            } else {
                const distBetween = distanceBetween(centerOfRectangle(rect), collisionCoordinates);

                if (distBetween < minDistanceToCenter) {
                    minDistanceToCenter = distBetween;
                    minDroppableContainer = droppableContainer.id;
                }
            }
        }
    }

    return minDroppableContainer;
}