import {getVisibleSelectionRect} from "draft-js";

export default function getSelectionRect() {
    let rect = getVisibleSelectionRect(window);
    if (rect) {
        return {
            top: Math.round(rect.top),
            left: Math.round(rect.left),
            width: Math.round(rect.width),
            height: Math.round(rect.height)
        };
    }


    const selection = window.getSelection();

    if (!selection?.rangeCount) {
        return;
    }

    let node: Node | null = selection?.getRangeAt(0).startContainer;

    while (node) {
        // @ts-ignore
        if (node.getAttribute('data-block') === 'true') {
            // @ts-ignore
            rect = node.getBoundingClientRect();

            if (rect) {
                return {
                    top: rect.top,
                    left: rect.left,
                    width: 0,
                    height: rect.height
                };
            }

            break;
        }

        node = node.parentNode;
    }
}