import StyleSet from "../types/StyleSet";
import merge from "ts-deepmerge";
import undefinify from "../../common/util/undefinify";
import normalizeStyle from "./styles/normalizeStyle";
import normalizeHeadlineStyle from "./styles/normalizeHeadlineStyle";
import normalizeTextStyle from "./styles/normalizeTextStyle";
import normalizeOrderedListStyle from "./styles/normalizeOrderedListStyle";
import normalizeUnorderedListStyle from "./styles/normalizeUnorderedListStyle";
import fromReference from "../../common/util/fromReference";

export default function normalizeStyleSet(styleSet: StyleSet) {
    const {
        position,
        colorSet,
        headline1Style,
        headline2Style,
        headline3Style,
        headline4Style,
        headline5Style,
        text1Style,
        text2Style,
        text3Style,
        unorderedListStyle,
        orderedListStyle,
        ...rest
    } = styleSet;

    const styleSetNormalized = {
        ...rest,
        colorSetId: fromReference(colorSet),
        headline1StyleId: headline1Style.id,
        headline2StyleId: headline2Style.id,
        headline3StyleId: headline3Style.id,
        headline4StyleId: headline4Style.id,
        headline5StyleId: headline5Style.id,
        text1StyleId: text1Style.id,
        text2StyleId: text2Style.id,
        text3StyleId: text3Style.id,
        orderedListStyleId: orderedListStyle.id,
        unorderedListStyleId: unorderedListStyle.id,
    }

    return merge({
            styleSets: {
                [styleSetNormalized.id]: undefinify(styleSetNormalized)
            }
        },
        normalizeHeadlineStyle(headline1Style),
        normalizeHeadlineStyle(headline2Style),
        normalizeHeadlineStyle(headline3Style),
        normalizeHeadlineStyle(headline4Style),
        normalizeHeadlineStyle(headline4Style),
        normalizeHeadlineStyle(headline5Style),
        normalizeTextStyle(text1Style),
        normalizeTextStyle(text2Style),
        normalizeTextStyle(text3Style),
        normalizeOrderedListStyle(orderedListStyle),
        normalizeUnorderedListStyle(unorderedListStyle),
    )
}