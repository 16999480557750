import BuilderState from "../../types/element/BuilderState";
import {v4 as uuid} from "uuid";
import addRow from "./addRow";
import RowNormalizedEntry from "../../types/element/RowNormalizedEntry";
import GroupNormalizedEntry from "../../types/element/GroupNormalizedEntry";
import createFieldCopy from "./createFieldCopy";
import addField from "./addField";
import createBuilderState from "../../factories/createBuilderState";
import appendElements from "./appendElements";

export default function createGroupCopy(elements: BuilderState, groupId: string): [GroupNormalizedEntry | undefined, BuilderState] {
    const group = elements.groups[groupId];
    const container = elements.containers[group?.containerId || ''];
    const page = elements.pages[container?.pageId || ''];

    const references = createBuilderState();

    if (!group || !container || !page) {
        return [undefined, references];
    }

    const groupCopy: GroupNormalizedEntry = {...group, id: uuid(), rowIds: [], containerId: undefined};
    // @ts-ignore
    references.groups[groupCopy.id] = groupCopy;

    group.rowIds.forEach(rowId => {
        const row = elements.rows[rowId];
        if (!row) return;
        const rowCopy: RowNormalizedEntry = {...row, id: uuid(), groupId: undefined, fieldIds: []};

        addRow(references, rowCopy, {type: 'group', groupId: groupCopy.id});

        row.fieldIds.forEach(fieldId => {
            const [fieldCopy, fieldReferences] = createFieldCopy(elements, fieldId);
            if (!fieldCopy) return;
            appendElements(references, fieldReferences)
            addField(references, fieldCopy, {type: 'row', rowId: rowCopy.id});
        });
    })

    return [groupCopy, references];
}