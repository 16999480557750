import React from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/SubmitButtonNormalizedEntry";
import LabelRte from "./LabelRte";
import useBuilderSelector from "../../../hooks/useBuilderSelector";

type Props = {
    fieldId: string
    editable?: boolean
}

function SubmitButton({fieldId, editable}: Props) {
    const label = useFieldSelector<Type>(fieldId, field => field?.label);
    const submitting = useBuilderSelector(state => state.data.submitting);

    return (
        <button type="submit" className="w-100 mt-3 btn btn-primary"
                disabled={submitting}
                dangerouslySetInnerHTML={editable ? undefined : {__html: label}}>
            {editable && <LabelRte fieldId={fieldId} textAlign="center"/>}
        </button>
    );
}

export default React.memo(SubmitButton);