import createBaseStyle from "./createBaseStyle";
import BaseTextStyle from "../../types/styles/BaseTextStyle";
import Reference from "../../../common/types/Reference";

export default function createBaseTextStyle(font: Reference): BaseTextStyle {
    return {
        ...createBaseStyle(),
        align: 'left',
        bold: false,
        colorIndex: 0,
        font: font,
        fontSize: 0,
        letterSpacing: 0,
        italic: false,
        lineHeight: 0,
        linkBold: false,
        linkColorIndex: 0,
        linkHoverBold: false,
        linkHoverColorIndex: 0,
        linkHoverItalic: false,
        linkHoverUnderline: false,
        linkItalic: false,
        linkUnderline: false,
        strikethrough: false,
        underline: false,
        uppercase: false
    };
}