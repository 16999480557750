import useColorerSelector from "../hooks/useColorerSelector";
import useColorerDispatch from "../hooks/useColorerDispatch";
import {colorerActions} from "../colorerSlice";
import styles from "./ColorListItem.module.scss";
import ListItem from "../../common/components/ListItem";

type Props = {
    colorId: string
    position: number
}

export default function ColorListItem({colorId, position}: Props) {
    const dispatch = useColorerDispatch();
    const name = useColorerSelector(state => state.colors[colorId]?.name);
    const hex = useColorerSelector(state => state.colors[colorId]?.hex);
    const readonly = useColorerSelector(state => state.colors[colorId]?.readonly);
    const isActive = useColorerSelector(state => state.activeColorId === colorId);

    const edit = () => {
        dispatch(colorerActions.openModal('color/edit'));
    }

    const activate = () => {
        dispatch(colorerActions.setActiveColorId(colorId));
    }

    const remove = () => {
        dispatch(colorerActions.removeColor(colorId));
    }

    return (
        <ListItem
            active={isActive}
            activate={activate}
            edit={!readonly ? edit : undefined}
            remove={!readonly ? remove : undefined}
        >
            <div className={styles.item}>
                <span className={styles.position}>#{position}</span>
                <span className={styles.preview} style={{backgroundColor: hex}}/>
                <span className={styles.name}>{name}</span>
                <span className={styles.hex}>({hex})</span>
            </div>
        </ListItem>
    )
}