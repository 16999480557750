import React from "react";
import FieldInside from "./FieldInside";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import CornerButton from "../../../common/components/CornerButton";
import styles from "./FieldEditable.module.scss"
import classNames from "classnames";

type Props = {
    fieldId: string
}

export default function FieldDragOverlay({fieldId}: Props) {
    return (
        <div className={classNames(styles.wrapper, styles.active)}>
            <div className={classNames('bg-white', styles.control)}>
                <CornerButton color="lightblue" position="top-left" icon={faArrowsAlt}/>
            </div>
            <div className={styles.inside} style={{zIndex: 999}}>
                <FieldInside fieldId={fieldId} editable/>
            </div>
        </div>
    );
}