import React from "react";

type Props = {
    fieldId: string
}

function Line({fieldId}: Props) {
    return (
        <hr/>
    );
}

export default React.memo(Line);