import getServerBaseUrl from "./getServerBaseUrl";

export default async function request<Body>(method: string, path: string, body?: string | object): Promise<Body | undefined> {
    const response = await fetch(getServerBaseUrl() + path, {
        method,
        body: body ? (typeof body === 'string' ? body : JSON.stringify(body)) : undefined,
        credentials: 'include',
    });

    if (response.redirected) {
        window.location.href = response.url + '?target_path=' + window.location.href;
        return undefined;
    }

    return await response.json();
}