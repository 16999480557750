import Field from "../types/fields/Field";
import undefinify from "../../common/util/undefinify";

export type FieldNormalized = ReturnType<typeof normalizedField>;

export default function normalizedField(field: Field, rowId?: string) {
    const {position, ...rest} = field;

    return {
        fields: {
            [field.id]: undefinify({
                ...rest,
                rowId
            })
        }
    }
}