import Group from "./Group";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import React from "react";
import ContainerDropzone from "./ContainerDropzone";
import isLastContainer from "../../util/element/isLastContainer";

type Props = {
    containerId: string
    editable?: boolean
}

function Container({containerId, editable}: Props) {
    const groupIds = useElementsSelector(elements => elements.containers[containerId]?.groupIds || []);
    const isLast = useElementsSelector(elements => isLastContainer(elements, containerId));

    return (
        <div className={"row" + (isLast ? '' : ' mb-4')}>
            {groupIds.map(groupId => (<Group groupId={groupId} key={groupId} editable={editable}/>))}
            {editable && <ContainerDropzone containerId={containerId}/>}
        </div>
    );
}

export default React.memo(Container);