import BuilderState from "../../types/element/BuilderState";
import GroupNormalizedEntry from "../../types/element/GroupNormalizedEntry";
import PageTarget from "../../types/targets/PageTarget";
import ContainerTarget from "../../types/targets/ContainerTarget";
import ContainerNormalizedEntry from "../../types/element/ContainerNormalizedEntry";
import createContainerNormalized from "../../factories/createContainerNormalized";
import addContainer from "./addContainer";
import calcContainerLeftWidth from "./calcContainerLeftWidth";

export default function addGroup(elements: BuilderState, group: GroupNormalizedEntry, target: PageTarget | ContainerTarget) {
    let container: ContainerNormalizedEntry | undefined;

    if (target.type !== "container") {
        container = createContainerNormalized();
        addContainer(elements, container, target);
    } else {
        container = elements.containers[target.containerId];
        if (!container) return;
    }

    const leftWidth = calcContainerLeftWidth(elements, container.id);

    if (!leftWidth) {
        console.error('No width left for adding new group');
        return;
    }

    //we need to resize group if group width is to large
    if (group.width > leftWidth) {
        group.width = leftWidth;
    }

    // @ts-ignore
    elements.groups[group.id] = group;
    group.containerId = container.id;
    container.groupIds.push(group.id);
}