import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import LineNormalizedEntry from "../../types/element/fields/LineNormalizedEntry";

type Payload = {
    width?: number
}

export default function createLine({width = COL_WIDTH}: Payload | undefined = {}): LineNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'line',
        ...baseField,
    }
}