import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import NumberSelect from "./NumberSelect";

type Props = {
    styleType: "text" | "headline"
    styleId: string
}

export default function LineHeightForm({styleId, styleType}: Props) {
    const dispatch = useDesignerDispatch();
    const lineHeight = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.lineHeight);
    const onChange = (lineHeight: number) => {
        dispatch(designerActions.updateBaseTextStyle({styleId, styleType, values: {lineHeight}}))
    };

    return (
        <>
            <label className="form-label small">Zeilenhöhe</label>
            <NumberSelect className="form-select-sm" min={8} max={30} value={lineHeight ?? 18} onChange={onChange}/>
        </>
    );
}

