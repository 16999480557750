import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import StyleToggle from "./StyleToggle";
import BaseTextStyle from "../../types/styles/BaseTextStyle";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type Props = {
    styleType: "text" | "headline"
    styleProp: KeysMatching<BaseTextStyle, boolean>
    styleId: string,
    icon: IconProp | string
}

type KeysMatching<T, V> = { [K in keyof T]-?: T[K] extends V ? K : never }[keyof T];

export default function BaseTextStyleToggle({styleId, styleType, styleProp, icon}: Props) {
    const dispatch = useDesignerDispatch();

    const value = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.[styleProp] ?? false);
    const onChange = (value: boolean) => {
        dispatch(designerActions.updateBaseTextStyle({styleId, styleType, values: {[styleProp]: value}}))
    };

    return (
        <StyleToggle value={value} onChange={onChange} icon={icon}/>
    );
}

