import Container from "./Container";
import React from 'react';
import useElementsSelector from "../../hooks/element/useElementsSelector";
import PageDroppable from "./PageDropzone";
import Form from "./Form";

type Props = {
    pageId: string
    editable?: boolean
}

function Page({pageId, editable}: Props) {
    const containerIds = useElementsSelector(elements => elements.pages[pageId]?.containerIds || []);

    return (
        <Form pageId={pageId} editable={editable}>
            <div className="p-5 m-5 bg-white ms-auto me-auto" style={{width: 800}}>
                {editable && <PageDroppable pageId={pageId} position={0}/>}
                {containerIds.map((containerId, index) => (
                    <React.Fragment key={containerId}>
                        <Container containerId={containerId} editable={editable}/>
                        {editable && <PageDroppable pageId={pageId} position={index + 1}/>}
                    </React.Fragment>
                ))}
            </div>
        </Form>
    );
}

export default React.memo(Page);