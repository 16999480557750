import React, {FormEvent, ReactNode} from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {dataActions} from "../../slices/dataSlice";

type Props = {
    pageId: string
    editable?: boolean
    children: ReactNode
};

export default function Form({children, editable, pageId}: Props) {
    const dispatch = useBuilderDispatch();

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (editable) {
            return;
        }

        dispatch(dataActions.startSubmit());
        if (e.currentTarget.checkValidity()) {
            await fetch(process.env.REACT_APP_SERVER_URL + '/page/submit/' + pageId, {
                method: 'POST',
                credentials: 'include',
                body: new FormData(e.currentTarget),
            });

            dispatch(dataActions.endSubmit({
                "3f4ac021-d73c-4fd8-8a72-042ea6350d89": {
                    message: "Error"
                }
            }))
        } else {
            dispatch(dataActions.endSubmit());
        }
    }

    const onChange = () => {
        console.log('w');
    }

    return (
        <form onSubmit={onSubmit} noValidate onChange={onChange}>
            {children}
        </form>
    );
}
