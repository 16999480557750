import {FormEvent, useState} from "react";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import createStyleSet from "../../factories/createStyleSet";
import useDesignerSelector from "../../hooks/useDesignerSelector";
import StyleSetForm from "../StyleSetForm";
import FormModal from "../../../common/components/FormModal";
import createReference from "../../../common/util/createReference";

export default function DesignNewModal() {
    const dispatch = useDesignerDispatch();
    const show = useDesignerSelector(state => state.modal === 'style/new');
    const [name, setName] = useState('');
    const [isDefault, setDefault] = useState(false);
    const colorSetId = useDesignerSelector(state => state.defaultColorSetId);
    const fontId = useDesignerSelector(state => state.defaultFontId);

    if (!show) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name) return;

        const styleSet = createStyleSet(name, isDefault, createReference(colorSetId), createReference(fontId));
        dispatch(designerActions.addStyleSet(styleSet));
        close();
    }

    const close = () => {
        setName('');
        setDefault(false);
        dispatch(designerActions.closeModal());
    };

    return (
        <FormModal title="Neues Design hinzufügen" cancel={close} submit={submit}>
            <StyleSetForm
                name={name} setName={setName}
                default={isDefault} setDefault={setDefault}
                submit={submit}/>
        </FormModal>
    )
}