import React, {ReactNode, useState} from "react";
import {useDroppable} from "@dnd-kit/core";
import {v4 as uuid} from "uuid";

type Props = {
    children: ReactNode
}

export default function DeadDropzone({children}: Props) {
    const [id] = useState(uuid())
    const {setNodeRef} = useDroppable({id});

    return (
        <div ref={setNodeRef} className="h-100">
            {children}
        </div>
    );
}