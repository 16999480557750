import arrayRemove from "../../../common/util/arrayRemove";
import removeRow from "./removeRow";
import {PageNormalized} from "../../normalizer/normalizePage";

export default function removeField(pageNormalized: PageNormalized, fieldId: string, keepReference?: boolean) {
    const field = pageNormalized.fields[fieldId];
    const row = pageNormalized.rows[field?.rowId || ''];

    if (!field) return;

    field.rowId = undefined;
    arrayRemove(row?.fieldIds || [], fieldId);
    !keepReference && delete pageNormalized.fields[fieldId]

    //remove the row if there is no field inside
    if (row && !row.fieldIds.length) {
        removeRow(pageNormalized, row.id);
    }
}