import classNames from "classnames";
import {faCog, faStar, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ReactNode} from "react";
import styles from "./ListItem.module.scss";

type Props = {
    withDefault?: boolean
    active?: boolean
    default?: boolean
    edit?: () => void
    remove?: () => void
    activate?: () => void
    children: ReactNode
}

export default function ListItem({edit, activate, default: isDefault, active, children, withDefault, remove}: Props) {
    return (
        <li onClick={activate} className={classNames(styles.item, {
            [styles.withDefault]: withDefault,
            [styles.isActive]: active,
            [styles.isDefault]: isDefault
        })}>
            {isDefault && <span className={styles.default}><FontAwesomeIcon icon={faStar}/></span>}
            <div className={styles.children}>{children}</div>
            {edit && (
                <button className={classNames("btn", "btn-link", styles.button)} onClick={edit}>
                    <FontAwesomeIcon icon={faCog}/>
                </button>
            )}
            {remove && (
                <button className={classNames("btn", "btn-link", styles.button)} onClick={remove}>
                    <FontAwesomeIcon icon={faTrashAlt}/>
                </button>
            )}
        </li>
    )
}