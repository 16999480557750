import normalizeHeadlineStyle from "./normalizeHeadlineStyle";
import HeadlineStyle from "../../types/styles/HeadlineStyle";
import createReference from "../../../common/util/createReference";

export default function denormalizeHeadlineStyle(normalized: ReturnType<typeof normalizeHeadlineStyle>, styleId: string): HeadlineStyle | undefined {
    const style = normalized.styles[styleId];
    if (!style) return undefined;

    const {fontId, ...rest} = style;
    return {...rest, font: createReference(fontId)}
}