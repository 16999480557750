import {Modal} from "react-bootstrap";
import {ReactNode} from "react";
import Button from "./Button";
import styles from "./FormModal.module.scss";

type Props = {
    title: string
    children: ReactNode
    cancel?: () => void
    submit?: () => void
}

export default function FormModal({title, children, cancel, submit}: Props) {
    return (
        <Modal show={true} onHide={cancel}
               dialogClassName={styles.dialog}
               contentClassName={styles.content} backdropClassName={styles.backdrop}>
            <Modal.Header>
                <div className={styles.title}>{title}</div>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <div className={styles.footer}>
                    <Button disabled={!cancel} className="btn-secondary" onClick={cancel}>Abbrechen</Button>
                    <Button disabled={!submit} onClick={submit}>Erstellen</Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
