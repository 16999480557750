import BaseTextStyleToggle from "./BaseTextStyleToggle";
import {faBold, faItalic, faStrikethrough, faUnderline} from "@fortawesome/free-solid-svg-icons";

type Props = {
    styleType: 'text' | 'headline'
    styleId: string
}

export default function TextStyleToggles({styleId, styleType}: Props) {
    return (
        <>
            <label className="form-label small">Schrift-Stil</label>
            <div className="d-flex">
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="bold" icon={faBold}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="italic" icon={faItalic}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="underline" icon={faUnderline}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="strikethrough"
                                     icon={faStrikethrough}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="uppercase" icon="UP"/>
            </div>
        </>
    );
}