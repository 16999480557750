import denormalizeColorSet from "./denormalizeColorSet";
import ColorSet from "../types/ColorSet";
import {ColorSetsNormalized} from "./normalizeStyleSets";

export default function denormalizeColorSets(normalized: ColorSetsNormalized): ColorSet[] {
    return normalized.colorSetIds.flatMap((colorSetId, i) => {
        const colorSet = denormalizeColorSet(normalized, colorSetId, i);
        return colorSet ? [colorSet] : [];
    });
}
