import useColorerSelector from "../hooks/useColorerSelector";
import useColorerDispatch from "../hooks/useColorerDispatch";
import {colorerActions} from "../colorerSlice";
import ListItem from "../../common/components/ListItem";

type Props = {
    colorSetId: string
}

export default function ColorSetListItem({colorSetId}: Props) {
    const dispatch = useColorerDispatch();
    const name = useColorerSelector(state => state.colorSets[colorSetId]?.name);
    const readonly = useColorerSelector(state => !!state.colorSets[colorSetId]?.readonly);
    const isDefault = useColorerSelector(state => !!state.colorSets[colorSetId]?.default);
    const isActive = useColorerSelector(state => state.activeColorSetId === colorSetId);

    const edit = () => {
        dispatch(colorerActions.openModal('color-set/edit'));
    }

    const activate = () => {
        dispatch(colorerActions.setActiveColorSetId(colorSetId));
    }

    const remove = () => {
        dispatch(colorerActions.removeColorSet(colorSetId));
    }

    return (
        <ListItem
            withDefault
            active={isActive}
            default={isDefault}
            edit={!readonly ? edit : undefined}
            remove={!readonly ? remove : undefined}
            activate={activate}
        >
            {name}
        </ListItem>
    )
}