import './packages/common/assets/scss/index.scss';
import "bootstrap/scss/bootstrap.scss"
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Builder from "./packages/builder/Builder";
import Colorer from "./packages/colorer/components/Colorer";
import Designer from "./packages/designer/components/Designer";

ReactDOM.render(
    <React.StrictMode>
        {(() => {
            switch (window.REACT_APP_PACKAGE) {
                case 'designer':
                    return <Designer/>;
                case 'colorer':
                    return <Colorer/>;
                case 'builder':
                    return <Builder pageId={window.REACT_APP_PAGE_ID} editable={window.REACT_APP_EDIT}/>;
                default:
                    return <div>Package "{window.REACT_APP_PACKAGE}" not found</div>
            }
        })()}
    </React.StrictMode>,
    document.getElementById('root')
);


// If you want your common to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
