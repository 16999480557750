import InputNormalizedEntry from "../../types/element/fields/InputNormalizedEntry";
import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import InputType from "../../types/element/fields/InputType";

type Payload = {
    inputType: InputType,
    label: string,
    width?: number
}

export default function createInput({inputType, label, width = COL_WIDTH}: Payload): InputNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'input',
        ...baseField,
        inputType,
        label
    }
}