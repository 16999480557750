import DesignerState from "../types/DesignerState";
import selectStyle from "./selectStyle";

export default function selectBaseTextStyle(state: DesignerState, type: 'headline' | 'text', styleId: string) {
    switch (type) {
        case "text":
            return selectStyle(state, 'text', styleId);
        case "headline":
            return selectStyle(state, 'headline', styleId);
    }
}