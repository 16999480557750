import {v4 as uuid} from "uuid";
import Color from "../types/Color";

export default function createColor(name: string, hex: string, description?: string): Color {
    return {
        id: uuid(),
        name,
        hex,
        description
    };
}
