import React from "react";
import useElementDroppable from "../../hooks/element/useElementDroppable";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import useBuilderSelector from "../../hooks/useBuilderSelector";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {editorActions} from "../../slices/editorSlice";

type Props = {
    pageId: string
    position: number
};

function PageDropzone({pageId, position}: Props) {
    const dispatch = useBuilderDispatch();
    const {setNodeRef, isOver} = useElementDroppable({type: 'page', pageId, position});
    const empty = useElementsSelector(elements => elements.pages[pageId]?.containerIds.length === 0);
    const isLast = useElementsSelector(elements => elements.pages[pageId]?.containerIds.length === position);
    const dragSource = useBuilderSelector(state => state.drag.source);

    if (!empty && (!dragSource || dragSource.type === 'field')) {
        return null;
    }

    return (
        <div
            ref={setNodeRef}
            onClick={empty ? () => dispatch(editorActions.setPanel('left')) : undefined}
            style={{
                opacity: isOver ? 1 : 0.5,
                cursor: empty ? 'pointer' : undefined
            }}
            className={"border text-center" + (isOver ? ' droppable' : '') + (position !== 0 ? ' mt-5' : '') + (isLast ? '' : ' mb-5')}>
            <div
                style={{
                    opacity: isOver ? 1 : 0.5,
                }}
                className="rounded-3 m-2 d-inline-block pe-4 ps-4">
                {empty ? "Drop field here" : "+ Add new group"}
            </div>
        </div>
    );
}

export default React.memo(PageDropzone);