

import useDesignerSelector from "../../hooks/useDesignerSelector";

type Props = {
    fontId: string
}

export default function FontSelectOption({fontId}: Props) {
    const name = useDesignerSelector(state => state.fonts[fontId]?.name)

    return (
        <option value={fontId}>{name}</option>
    );
}

