import styles from "./List.module.scss";
import {ReactNode} from "react";
import Button from "./Button";

type Props = {
    children: ReactNode[]
    withDefault?: boolean
    add?: {
        label: string
        callback: () => void
    },
}

export default function List({children, add, withDefault}: Props) {
    return (
        <div className={withDefault ? styles.withDefault : undefined}>
            {!!children.length && (
                <ul className="list-unstyled mb-4">
                    {children}
                </ul>
            )}
            {add && (
                <Button className={styles.button} size="sm"
                        onClick={add.callback}>
                    + {add.label}
                </Button>
            )}
        </div>
    )
}