import {useDroppable} from "@dnd-kit/core";
import ElementTarget from "../../types/targets/ElementTarget";

export default function useElementDroppable(target: ElementTarget) {
    return useDroppable({id: getId(target), data: target});
}

function getId(target: ElementTarget) {
    switch (target.type) {
        case "page":
            return target.type + ':' + target.pageId + ':' + target.position;
        case "container":
            return target.type + ':' + target.containerId;
        case "group":
            return target.type + ':' + target.groupId + ':' + target.position;
        case "row":
            return target.type + ':' + target.rowId;
    }
}