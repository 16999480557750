import React, {useEffect, useRef, useState} from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/InputNormalizedEntry";
import LabelRte from "./LabelRte";
import useBuilderSelector from "../../../hooks/useBuilderSelector";
import {dataActions} from "../../../slices/dataSlice";
import useBuilderDispatch from "../../../hooks/useBuilderDispatch";
import classNames from "classnames";

type Props = {
    fieldId: string
    editable?: boolean
}

function useFormData(fieldId: string, defaultValue: string): [string, (value: string) => void, () => void] {
    const dispatch = useBuilderDispatch();
    const dataValue = useBuilderSelector(state => state.data.values[fieldId])
    const [value, setValue] = useState<string>(defaultValue);

    useEffect(() => {
        if (dataValue) {
            setValue(dataValue);
        }

    }, [dataValue])

    const submit = () => {
        //needs validation
        dispatch(dataActions.setValue({fieldId, value}))
    }

    return [value, setValue, submit]
}



function Input({fieldId, editable}: Props) {
    const input = useRef<HTMLInputElement>(null);
    const label = useFieldSelector<Type>(fieldId, field => field?.label);
    const inputType = useFieldSelector<Type>(fieldId, field => field?.inputType);
    const error = useBuilderSelector(state => state.data.errors[fieldId]);
    const validated = useBuilderSelector(state => state.data.validated);
    const [value, setValue, submit] = useFormData(fieldId, '');

    return (
        <div className={classNames({'was-validated': validated && !error})}>
            <label htmlFor={fieldId}
                   className="form-label"
                   dangerouslySetInnerHTML={editable ? undefined : {__html: label}}
            >
                {editable && <LabelRte fieldId={fieldId}/>}
            </label>
            <input name={fieldId} ref={input} type={inputType} value={value} onChange={e => {
                setValue(e.target.value);
                // dispatch((dispatch, getState) => getState().data.validated && dispatch(dataActions.setValidated(false)));
            }} onBlur={submit}
                   className={classNames("form-control", {"is-invalid": !!error})} required/>
            <div className="invalid-feedback">
                {error ? error.message : "INVALID MESSAGE"}
            </div>
        </div>
    );
}

export default React.memo(Input);