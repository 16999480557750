import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import createColorerState from "./factories/createColorerState";
import {ColorerModal} from "./types/ColorerState";
import setDefaultColorSet from "./util/setDefaultColorSet";
import mergeDraftState from "../common/util/mergeDraftState";
import ColorSet from "./types/ColorSet";
import normalizeColorSets from "./normalizer/normalizeStyleSets";
import arrayRemove from "../common/util/arrayRemove";
import Color from "./types/Color";
import normalizeColor from "./normalizer/normalizeColor";

const colorerSlice = createSlice({
    name: 'colorer',
    initialState: createColorerState(),
    reducers: {
        init: (state, {payload: colorSets}: PayloadAction<ColorSet[]>) => {
            state.initialized = true;
            mergeDraftState(state, normalizeColorSets(colorSets));

            colorSets.forEach(colorSet => {
                if (colorSet.default) {
                    state.activeColorSetId = colorSet.id;
                }
            });

            if (!colorSets.length) {
                state.activeColorSetId = undefined;
                state.activeColorId = undefined
            }
        },
        addColorSet: (state, {payload: colorSet}: PayloadAction<ColorSet>) => {
            mergeDraftState(state, normalizeColorSets([colorSet]));

            if (colorSet.default) {
                setDefaultColorSet(state, colorSet.id);
            }
        },
        removeColorSet: (state, {payload: colorSetId}: PayloadAction<string>) => {
            if (state.colorSets[colorSetId]?.default) {
                setDefaultColorSet(state, undefined);
            }

            arrayRemove(state.colorSetIds, colorSetId);

            if (state.activeColorSetId === colorSetId) {
                state.activeColorSetId = state.colorSetIds[0];
                state.activeColorId = state.colorSets[state.colorSetIds[0] || '']?.colorIds[0];
            }
        },
        updateColorSet: (state, {payload}: PayloadAction<{ colorSetId: string, values: Partial<Omit<ColorSet, 'id' | 'position' | 'colors'>> }>) => {
            const {colorSetId, values} = payload;
            const colorSet = state.colorSets[colorSetId];
            if (!colorSet) {
                return;
            }

            state.colorSets[colorSetId] = {...colorSet, ...values};

            if (typeof values.default !== undefined && values.default !== colorSet.default) {
                setDefaultColorSet(state, values.default ? colorSetId : undefined);
            }
        },
        addColor: (state, {payload}: PayloadAction<{ colorSetId: string, color: Color }>) => {
            const {colorSetId, color} = payload;
            const colorSet = state.colorSets[colorSetId];

            if (!colorSet) {
                console.error('ColorSet not found:', {colorSetId, state});
                return;
            }

            mergeDraftState(state, normalizeColor(color, colorSetId));
            colorSet.colorIds.push(color.id);
        },
        removeColor: (state, {payload: colorId}: PayloadAction<string>) => {
            const colorSet = state.colorSets[state.colors[colorId]?.colorSetId || ''];

            if (!colorSet) {
                console.error('ColorSet not found:', {colorId, colorSet});
                return;
            }

            arrayRemove(colorSet.colorIds, colorId);
        },
        updateColor: (state, {payload}: PayloadAction<{ colorId: string, values: Partial<Omit<Color, | 'id' | 'position'>> }>) => {
            const {colorId, values} = payload;
            const color = state.colors[colorId];
            if (!color) {
                console.error('Color not found:', {colorId, state});
                return;
            }

            state.colors[colorId] = {...color, ...values};
        },
        setActiveColorSetId: (state, {payload: colorSetId}: PayloadAction<string | undefined>) => {
            if (state.activeColorId !== colorSetId) {
                state.activeColorSetId = colorSetId;
                state.activeColorId = state.colorSets[colorSetId || '']?.colorIds[0];
            }
        },
        setActiveColorId: (state, {payload: colorId}: PayloadAction<string | undefined>) => {
            state.activeColorId = colorId;
            state.activeColorSetId = state.colors[colorId || '']?.colorSetId;
        },
        openModal: (state, {payload: modal}: PayloadAction<ColorerModal>) => {
            state.modal = modal;
        },
        closeModal: (state) => {
            state.modal = undefined;
        }
    }
});

export const colorerReducer = colorerSlice.reducer;
export const colorerActions = colorerSlice.actions;

