import ColorerState from "../types/ColorerState";

export default function setDefaultColorSet(state: ColorerState, colorStateId: string | undefined) {
    if (colorStateId) {
        state.colorSetIds.forEach(colorSetId => {
            const colorSet = state.colorSets[colorSetId];
            if (colorSet) colorSet.default = false;
        });
    }
    const colorSet = state.colorSets[colorStateId || state.colorSetIds[0]]
    if (colorSet) colorSet.default = true;
}