import React from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {builderActions} from "../../slices/builderSlice";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    groupId: string
}

function GroupDeleteButton({groupId}: Props) {
    const dispatch = useBuilderDispatch();

    return (
        <CornerButton icon={faTrashAlt} position="bottom-right" color="red"
                      onClick={() => dispatch(builderActions.removeGroup({groupId}))}>
            Löschen
        </CornerButton>
    );
}

export default React.memo(GroupDeleteButton);