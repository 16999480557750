import {Reducer} from "redux";

export default function reduceUndoable<State>(reducer: Reducer<State>): Reducer<State> {
    return (state, action) => {
        let nextState = reducer(state, action);
        const types = ['@@redux-undo/UNDO', '@@redux-undo/REDO'];

        // @ts-ignore
        if (types.indexOf(action.type) >= 0 && nextState.present?.editor?.panel === 'left') {
            // @ts-ignore
            const present = nextState.present, editor = present.editor;

            // @ts-ignore
            nextState = {...nextState, present: {...present, editor: {...editor, panel: undefined}}};
        }

        return nextState;
    }
}
