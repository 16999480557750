import List from "../../common/components/List";
import ListItem from "../../common/components/ListItem";
import {designerActions} from "../designerSlice";
import useDesignerDispatch from "../hooks/useDesignerDispatch";
import useDesignerSelector from "../hooks/useDesignerSelector";
import {StyleType} from "../types/DesignerState";

export default function DesignElementList() {
    const dispatch = useDesignerDispatch();
    const activeStyleType = useDesignerSelector(state => state.activeStyleType);
    const activeStyleSetId = useDesignerSelector(state => state.activeStyleSetId);

    const items: ({ styleType: StyleType, label: string })[] = [
        {styleType: "headline1", label: "Überschrift 1"},
        {styleType: "headline2", label: "Überschrift 2"},
        {styleType: "headline3", label: "Überschrift 3"},
        {styleType: "headline4", label: "Überschrift 4"},
        {styleType: "headline5", label: "Überschrift 5"},
        {styleType: "text1", label: "Text 1"},
        {styleType: "text2", label: "Text 2"},
        {styleType: "text3", label: "Text 3"},
        {styleType: "unordered-list", label: "Ungeordnete Liste"},
        {styleType: "ordered-list", label: "Geordnete Liste"},
    ];

    const activate = (styleType: StyleType) => {
        dispatch(designerActions.setActiveStyleType(styleType))
    }

    if (!activeStyleSetId) {
        return null;
    }

    return (
        <List>
            {items.map(item => (
                <ListItem active={item.styleType === activeStyleType}
                          activate={() => activate(item.styleType)}
                          key={item.styleType}>
                    {item.label}
                </ListItem>
            ))}
        </List>
    );
}