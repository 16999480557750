import React from "react";
import styles from "./ResizeButton.module.scss";
import classNames from "classnames";

type Props = {
    position?: 'left' | 'right'
    color?: 'lightblue' | 'violet'
} & React.ComponentProps<"button">

export default function ResizeButton({position = 'right', color = 'lightblue', ...rest}: Props) {
    return (
        <button className={classNames(
            styles.button,
            styles['color-' + color],
            styles['position-' + position],
        )} {...rest} />
    );
}