import merge from "ts-deepmerge";
import normalizeColorSet from "./normalizeColorSet";
import ColorSet from "../types/ColorSet";

export type ColorSetsNormalized = ReturnType<typeof normalizeColorSets>;

export default function normalizeColorSets(colorSets: ColorSet[]) {
    return merge(...colorSets.map(normalizeColorSet), {
        colorSetIds: colorSets.map(colorSet => colorSet.id)
    });
}