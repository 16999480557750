import Style from "../types/Style";
import DesignerState from "../types/DesignerState";
import normalizeStyle from "../normalizer/styles/normalizeStyle";
import normalizeTextStyle from "../normalizer/styles/normalizeTextStyle";
import normalizeHeadlineStyle from "../normalizer/styles/normalizeHeadlineStyle";
import normalizeUnorderedListStyle from "../normalizer/styles/normalizeUnorderedListStyle";
import normalizeOrderedListStyle from "../normalizer/styles/normalizeOrderedListStyle";

type NormalizedEntry = ReturnType<typeof normalizeStyle>["styles"][""];

export default function selectStyle(state: DesignerState, type: 'text', styleId: string): ReturnType<typeof normalizeTextStyle>["styles"][""];
export default function selectStyle(state: DesignerState, type: 'headline', styleId: string): ReturnType<typeof normalizeHeadlineStyle>["styles"][""];
export default function selectStyle(state: DesignerState, type: 'unordered-list', styleId: string): ReturnType<typeof normalizeUnorderedListStyle>["styles"][""];
export default function selectStyle(state: DesignerState, type: 'ordered-list', styleId: string): ReturnType<typeof normalizeOrderedListStyle>["styles"][""];
export default function selectStyle<T extends NormalizedEntry>(state: DesignerState, type: Style['type'], styleId: string): T | undefined {
    const style = state.styles[styleId] as NormalizedEntry;

    if (style?.type === type) {
        return style as T;
    } else {
        return undefined;
    }
}