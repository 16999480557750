import React from "react";
import DragFieldFixture from "./element/DragFieldFixture";
import getFixtureIds from "../util/element/getFixtureIds";
import {CSSTransition} from 'react-transition-group';
import useBuilderSelector from "../hooks/useBuilderSelector";
import useBuilderDispatch from "../hooks/useBuilderDispatch";
import {editorActions} from "../slices/editorSlice";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import DeadDropzone from "./element/DeadDropzone";
import IconButton from "../../common/components/IconButton";
import classNames from "classnames";

export default function LeftPanel() {
    const dispatch = useBuilderDispatch();
    const open = useBuilderSelector(state => state.undoable.present.editor.panel === 'left');
    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <>
            <IconButton className="position-absolute mt-5 ms-5"
                        direction="right" size="lg" color="blue"
                        icon={faPlus}
                        onClick={() => dispatch(editorActions.setPanel('left'))}>
                Element hinzufügen
            </IconButton>
            <div className={classNames('left-panel', {'pe-none': !open})}>
                <CSSTransition nodeRef={ref} in={open} timeout={{exit: 400}} unmountOnExit={true}>
                    <div className="inner" ref={ref}>
                        <DeadDropzone>
                            {getFixtureIds().map(fixtureId => (
                                <DragFieldFixture key={fixtureId} fixtureId={fixtureId}/>
                            ))}

                        </DeadDropzone>
                    </div>
                </CSSTransition>
            </div>
        </>
    );
}