import React from "react";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import Input from "./fields/Input";
import SubmitButton from "./fields/SubmitButton";
import Headline from "./fields/Headline";
import Text from "./fields/Text";
import Image from "./fields/Image";
import Line from "./fields/Line";

type Props = {
    fieldId: string
    editable?: boolean
}

function FieldInside({fieldId, editable}: Props) {
    const type = useElementsSelector(elements => elements.fields[fieldId]?.type);

    if (!type) {
        return null;
    }

    switch (type) {
        case "input":
            return <Input fieldId={fieldId} editable={editable}/>
        case "submit-button":
            return <SubmitButton fieldId={fieldId} editable={editable}/>
        case "headline":
            return <Headline fieldId={fieldId} editable={editable}/>
        case "text":
            return <Text fieldId={fieldId} editable={editable}/>
        case "image":
            return <Image fieldId={fieldId}/>
        case "line":
            return <Line fieldId={fieldId}/>
    }
}

export default React.memo(FieldInside);