import React, {useEffect, useState} from "react";
import {DragOverlay as DndDragOverlay} from "@dnd-kit/core";
import DragOverlayInside from "./DragOverlayInside";
import useBuilderSelector from "../../hooks/useBuilderSelector";


export default function DragOverlay() {
    const [dropAnimation, setDropAnimation] = useState<null>();
    const dragSource = useBuilderSelector(state => state.drag.source);

    useEffect(() => {
        if (!dragSource) {
            return;
        }

        setDropAnimation(dragSource?.type === 'fixture' ? null : undefined);
    }, [dragSource]);

    return (
        <DndDragOverlay dropAnimation={dropAnimation}>
            {dragSource ?
                <div className="drag-overlay opacity-75" style={{zIndex: 9999999}}>
                    <DragOverlayInside dragSource={dragSource}/>
                </div> :
                null
            }
        </DndDragOverlay>
    );
}