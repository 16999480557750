import Fixture from "../../types/element/fields/Fixture";
import fixtures from "../../fixtures";
import MapById from "../../../common/types/MapById";

let fixturesMap: MapById<Fixture> = {};

fixtures.forEach(fixture => {
    fixturesMap[fixture.id] = fixture;
});

export default function getFixture(fixtureId: string) {
    return fixturesMap[fixtureId];
}