import BuilderState from "../../types/element/BuilderState";
import removeGroup from "./removeGroup";
import addGroup from "./addGroup";
import PageTarget from "../../types/targets/PageTarget";
import ContainerTarget from "../../types/targets/ContainerTarget";

export default function moveGroup(elements: BuilderState, groupId: string, target: PageTarget | ContainerTarget) {
    const group = elements.groups[groupId];

    if (!group) {
        return;
    }

    removeGroup(elements, groupId, true);
    addGroup(elements, group, target);
}