import Page from "../types/Page";
import merge from "ts-deepmerge";
import normalizeContainer from "./normalizeContainer";
import undefinify from "../../common/util/undefinify";

export type PageNormalized = ReturnType<typeof normalizePage>;

export default function normalizePage(page: Page) {
    const {containers, ...rest} = page;

    return merge({
        pages: {
            [page.id]: undefinify({
                ...rest,
                containerIds: containers.map(container => container.id)
            })
        }
    }, ...containers.map(container => normalizeContainer(container, page.id)))
}