import Font from "../types/Font";
import {v4 as uuid} from "uuid";

export default function createFont(name: string): Font {
    return {
        id: uuid(),
        name,
        position: 0,
        genericName: 'sans-serif'
    };
}