import React, {useRef} from "react";
import {COL_WIDTH} from "../../../../config";
import getPercent from "../../../common/util/getPercent";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import GroupInside from "./GroupInside";
import GroupEditable from "./GroupEditable";

type Props = {
    groupId: string
    editable?: boolean
};

function Group({groupId, editable}: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const width = useElementsSelector(elements => elements.groups[groupId]?.width || COL_WIDTH);

    return (
        <div ref={ref} style={{width: getPercent(width, COL_WIDTH)}}>
            {editable ? <GroupEditable groupId={groupId} groupRef={ref}/> : <GroupInside groupId={groupId}/>}
        </div>
    );
}

export default React.memo(Group);
