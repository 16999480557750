import Page from "../../types/Page";
import BuilderState from "../../types/element/BuilderState";
import Container from "../../types/Container";
import Group from "../../types/Group";
import Row from "../../types/Row";
import Field from "../../types/fields/Field";

export default function toApiPage(elements: BuilderState, pageId: string): Page | undefined {
    const page = elements.pages[pageId];
    if (!page) return undefined;
    const {containerIds, ...basePage} = page;

    const apiPage: Page = {
        ...basePage,
        containers: []
    };

    containerIds.forEach((containerId, index) => {
        const container = elements.containers[containerId];
        if (!container) return;
        const {pageId, groupIds, ...baseContainer} = container;

        const apiContainer: Container = {
            ...baseContainer,
            position: index,
            groups: []
        };

        apiPage.containers.push(apiContainer);

        groupIds.forEach((groupId, index) => {
            const group = elements.groups[groupId];
            if (!group) return;
            const {containerId, rowIds, ...baseGroup} = group;

            const apiGroup: Group = {
                ...baseGroup,
                position: index,
                rows: []
            };

            apiContainer.groups.push(apiGroup);

            rowIds.forEach((rowId, index) => {
                const row = elements.rows[rowId];
                if (!row) return;
                const {groupId, fieldIds, ...baseRow} = row;

                const apiRow: Row = {
                    ...baseRow,
                    position: index,
                    fields: []
                };

                apiGroup.rows.push(apiRow);

                fieldIds.forEach((fieldId, index) => {
                    const field = elements.fields[fieldId];
                    if (!field) return;
                    const {rowId, ...baseField} = field;

                    const apiField: Field = {
                        ...baseField,
                        position: index,
                    };

                    apiRow.fields.push(apiField);
                });
            });
        });
    })

    return apiPage;
}
