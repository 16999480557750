import {ColorSetsNormalized} from "../normalizer/normalizeStyleSets";

export const colorerModals = <const>[
    'color-set/new', 'color-set/edit', 'color/new', 'color/edit'
];

export type ColorerModal = typeof colorerModals[number];

export default interface ColorerState extends ColorSetsNormalized {
    initialized: boolean,
    activeColorId?: string,
    activeColorSetId?: string
    modal?: ColorerModal
}