import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import BuilderState from "../types/element/BuilderState";
import DragSource from "../types/drag/DragSource";

const initialState: {
    active: boolean
    elements?: BuilderState
    source?: DragSource
} = {active: false};

const dragSlice = createSlice({
    name: 'drag',
    initialState,
    reducers: {
        start: (state, {payload}: PayloadAction<{ elements?: BuilderState, source?: DragSource }>) => {
            state.active = true;
            state.elements = payload.elements;
            state.source = payload.source;
        },
        end: state => {
            state.active = false;
            delete state.elements;
            delete state.source;
        },
    }
});

export const dragReducer = dragSlice.reducer;
export const dragActions = dragSlice.actions;

