import React from "react";
import GroupResizeButton from "./GroupResizeButton";
import useBuilderSelector from "../../hooks/useBuilderSelector";
import GroupDeleteButton from "./GroupDeleteButton";
import GroupMoveButton from "./GroupMoveButton";
import GroupCopyButton from "./GroupCopyButton";
import GroupConfigButton from "./GroupConfigButton";
import GroupInside from "./GroupInside";
import classNames from "classnames";
import styles from "./GroupEditable.module.scss";
import useGroupActive from "../../hooks/element/useGroupActive";

type Props = {
    groupId: string
    groupRef: React.RefObject<HTMLDivElement>
}

function GroupEditable({groupId, groupRef}: Props) {
    const [active, setActive] = useGroupActive(groupId);
    const isDragging = useBuilderSelector(state => state.drag.active);

    return (
        <div className={classNames(styles.wrapper, {[styles.active]: active, 'pe-none': isDragging})}
             onMouseDown={e => {
                 if (!isDragging) {
                     e.stopPropagation();
                     setActive(true);
                 }
             }}
        >
            <div className={styles.control}>
                {active ? (
                    <>
                        <GroupConfigButton groupId={groupId}/>
                        <GroupCopyButton groupId={groupId}/>
                        <GroupMoveButton groupId={groupId} groupRef={groupRef}/>
                        <GroupResizeButton groupId={groupId} groupRef={groupRef}/>
                        <GroupDeleteButton groupId={groupId}/>
                    </>
                ) : <div className={styles.hoverLayer}/>}
            </div>
            <div className={styles.inside}>
                <GroupInside groupId={groupId} editable/>
            </div>
        </div>
    );
}

export default React.memo(GroupEditable);