import React from "react";
import {CSSTransition} from 'react-transition-group';
import useBuilderSelector from "../hooks/useBuilderSelector";
import useBuilderDispatch from "../hooks/useBuilderDispatch";
import {editorActions} from "../slices/editorSlice";
import {faPencilRuler} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../common/components/IconButton";
import classNames from "classnames";

export default function RightPanel() {
    const dispatch = useBuilderDispatch();
    const open = useBuilderSelector(state => state.undoable.present.editor.panel === 'right');
    const ref = React.useRef<HTMLDivElement>(null);

    return (
        <>
            <IconButton className="position-absolute mt-5 me-5 end-0 top-0"
                        direction="left" size="lg" color="blue"
                        icon={faPencilRuler}
                        onClick={() => dispatch(editorActions.setPanel('right'))}>
                Layout bearbeiten
            </IconButton>

            <div className={classNames('right-panel', {'pe-none': !open})}>
                <CSSTransition nodeRef={ref} in={open} timeout={{exit: 400}} unmountOnExit={true}>
                    <div className="inner" ref={ref}/>
                </CSSTransition>
            </div>
        </>
    );
}