import PageNoramlized from "../../types/element/BuilderState";
import arrayRemove from "../../../common/util/arrayRemove";
import removeField from "./removeField";
import removeGroup from "./removeGroup";

export default function removeRow(pageNormalized: PageNoramlized, rowId: string) {
    const row = pageNormalized.rows[rowId];
    const group = pageNormalized.groups[row?.groupId || ''];

    if (!row) return;

    row.fieldIds.forEach(fieldId => removeField(pageNormalized, fieldId));
    row.groupId = undefined;
    arrayRemove(group?.rowIds || [], rowId);
    delete pageNormalized.rows[rowId]

    //remove the group if there is no row inside
    if (group && !group.rowIds.length) {
        removeGroup(pageNormalized, group.id);
    }
}