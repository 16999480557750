import Field from "./Field";
import React from "react";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import RowDropzone from "./RowDropzone";
import isLastRow from "../../util/element/isLastRow";

type Props = {
    rowId: string,
    editable?: boolean
}

function Row({rowId, editable}: Props) {
    const fieldIds = useElementsSelector(elements => elements.rows[rowId]?.fieldIds || []);
    const isLast = useElementsSelector(elements => isLastRow(elements, rowId));

    return (
        <div className={"row" + (isLast ? '' : ' mb-4')}>
            {fieldIds.map(fieldId => <Field fieldId={fieldId} key={fieldId} editable={editable}/>)}
            {editable && <RowDropzone rowId={rowId}/>}
        </div>
    );
}

export default React.memo(Row);