import React, {useEffect, useState} from "react";
import designerStore, {DesignerRootState} from "../designerStore";
import isServerActive from "../../common/util/isServerActive";
import request from "../../common/util/request";
import DesignList from "./DesignList";
import {DesignerModal, designerModals, StyleType, styleTypes} from "../types/DesignerState";
import {designerActions} from "../designerSlice";
import Sets from "../../common/components/Sets";
import DesignNewModal from "./modals/DesignNewModal";
import DesignEditModal from "./modals/DesignEditModal";
import DesignElementList from "./DesignElementList";
import denormalizeStyleSets from "../normalizer/denormalizeStyleSets";
import DesignElementForm from "./DesignElementForm";
import useSyncUrlParams from "../../common/hooks/useSyncUrlParams";
import useDesignerDispatch from "../hooks/useDesignerDispatch";
import fetchStyleSets from "../actions/fetchStyleSets";
import useDesignerSelector from "../hooks/useDesignerSelector";

export default function DesignSets() {
    const dispatch = useDesignerDispatch();
    const initialized = useDesignerSelector(state => state.initialized);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if(!initialized) {
            dispatch(fetchStyleSets());
        }
    }, [initialized, dispatch]);

    useSyncUrlParams<DesignerRootState>(designerStore, {
        styleSet: [state => state.activeStyleSetId, designerActions.setActiveStyleId],
        style: [state => state.activeStyleType, val => {
            if (val && styleTypes.includes(val as StyleType)) {
                return designerActions.setActiveStyleType(val as StyleType)
            } else {
                return designerActions.setActiveStyleType('headline1');
            }
        }],
        designerModal: [state => state.modal, val => {
            if (val && designerModals.includes(val as DesignerModal)) {
                return designerActions.openModal(val as DesignerModal)
            } else {
                return designerActions.closeModal();
            }
        }],
    });

    const save = async () => {
        if (isServerActive()) {
            setSaving(true);
            await request('PUT', '/styles.json', denormalizeStyleSets(designerStore.getState()));
            setSaving(false);
        }
    }

    const cancel = () => {
        //designerStore.dispatch(designerActions.setColorSets(styleSetsInitial));
    };

    return (
        <>
            <Sets title="Designer" loading={!initialized} save={save} saving={saving} cancel={cancel}>{[
                {
                    withDefault: true,
                    title: "Designs",
                    children: <DesignList/>,
                    col: 3
                },
                {
                    title: "Elemente",
                    children: <DesignElementList/>,
                    col: 3
                },
                {
                    title: "Style",
                    children: <DesignElementForm/>,
                    col: 3
                }
            ]}
            </Sets>
            <DesignNewModal/>
            <DesignEditModal/>
        </>
    )
}