import Color from "../types/Color";
import {ColorNormalized} from "./normalizeColor";

export default function denormalizeColor(normalized: ColorNormalized, colorId: string, position: number): Color | undefined {
    const normalizedColor = normalized.colors[colorId];

    if (!normalizedColor) {
        console.error('Color could not be denormalized. Color not found:', {colorId, normalized})
        return undefined;
    }

    const {colorSetId, readonly, ...rest} = normalizedColor;

    return {...rest, position};
}