import BuilderState from "../../types/element/BuilderState";
import getFieldWidth from "./getFieldWidth";

export default function calcGroupMinWidth(elements: BuilderState, groupId: string) {
    const group = elements.groups[groupId];

    let min = 1;

    group?.rowIds.forEach(rowId => {
        const rowMin = elements.rows[rowId]?.fieldIds.reduce((sum, fieldId) => {
            return sum + getFieldWidth(elements, fieldId);
        }, 0);

        if (rowMin && min < rowMin) {
            min = rowMin;
        }
    })

    return min;
}