import React from "react";
import DragOverlayFixture from "./DragOverlayFixture";
import FieldDragOverlay from "./FieldDragOverlay";
import DragSource from "../../types/drag/DragSource";
import GroupDragOverlay from "./GroupDragOverlay";

type Props = {
    dragSource: DragSource
}

function DragOverlayInside({dragSource}: Props) {
    switch (dragSource.type) {
        case "fixture":
            return <DragOverlayFixture fixtureId={dragSource.fixtureId}/>
        case "field":
            return <FieldDragOverlay fieldId={dragSource.fieldId}/>
        case "group":
            return <GroupDragOverlay groupId={dragSource.groupId}/>
    }
}

export default React.memo(DragOverlayInside);