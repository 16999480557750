import BuilderState from "../../types/element/BuilderState";

export default function resizeField(elements: BuilderState, fieldId: string, width: number) {
    const field = elements.fields[fieldId];

    if (!field) {
        return;
    }

    field.width = width;
}