import undefinify from "../../common/util/undefinify";
import Color from "../types/Color";

export type ColorNormalized = ReturnType<typeof normalizeColor>;

export default function normalizeColor(color: Color, colorSetId?: string, readonly?: boolean) {
    const {position, ...rest} = color;

    return {
        colors: {
            [color.id]: undefinify({...rest, colorSetId, readonly}),
        }
    }
}