import React, {RefObject, useRef} from "react";
import Page from "./element/Page";
import {DndContext} from "@dnd-kit/core";
import useBuilderDispatch from "../hooks/useBuilderDispatch";
import DragOverlay from "./element/DragOverlay";
import logo from "../../common/assets/images/logo.svg"
import RedoButton from "./header/RedoButton";
import UndoButton from "./header/UndoButton";
import LeftPanel from "./LeftPanel";
import onDragEndAction from "../util/drag/onDragEndAction";
import onDragStartAction from "../util/drag/onDragStartAction";
import RightPanel from "./RightPanel";
import closestDropzone from "../util/drag/closestDropzone";
import styles from "./Editor.module.scss";
import clearActiveAction from "../util/element/clearActiveAction";

type Props = {
    pageId: string
}

export const EditorContext = React.createContext<RefObject<HTMLDivElement> | null>(null);
EditorContext.displayName = "EditorContext";

function Editor({pageId}: Props) {
    const dispatch = useBuilderDispatch();
    const contentRef = useRef<HTMLDivElement>(null);

    return (
        <EditorContext.Provider value={contentRef}>
            <DndContext
                collisionDetection={closestDropzone}
                onDragStart={e => dispatch(onDragStartAction(e))}
                onDragEnd={e => dispatch(onDragEndAction(e))}
            >
                <div className={styles.header}>
                    <div className={styles.left}>
                        <div className={styles.btnGroup}>
                            <UndoButton/>
                            <RedoButton/>
                        </div>
                    </div>
                    <img className={styles.logo} alt="logo" src={logo}/>
                    <div className={styles.right}/>
                </div>
                <div className={styles.subheader}/>

                <div className={styles.body}
                     onMouseDown={() => {
                         dispatch((dispatch, getState) => {
                             if (!getState().drag.active) {
                                 dispatch(clearActiveAction());
                             }
                         });
                     }}>
                    <LeftPanel/>
                    <div className={styles.content} ref={contentRef}>
                        {pageId && <Page pageId={pageId} editable={true}/>}
                        <DragOverlay/>
                    </div>
                    <RightPanel/>
                </div>
                <div className={styles.footer}/>
            </DndContext>
        </EditorContext.Provider>
    );
}

export default React.memo(Editor);