import ColorSet from "../types/ColorSet";
import merge from "ts-deepmerge";
import undefinify from "../../common/util/undefinify";
import normalizeColor from "./normalizeColor";

export type ColorSetNormalized = ReturnType<typeof normalizeColorSet>;

export default function normalizeColorSet(colorSet: ColorSet) {
    const {
        colors,
        position,
        ...rest
    } = colorSet;

    const colorSetNormalized = {
        ...rest,
        colorIds: colors.map(color => color.id)
    }

    return merge({
            colorSets: {
                [colorSetNormalized.id]: undefinify(colorSetNormalized)
            }
        },
        ...colors.map(color => normalizeColor(color, colorSet.id, colorSet.readonly)),
    )
}