import createFieldCopy from "./createFieldCopy";
import getElementsByRootState from "./getElementsByRootState";
import getGroupByFieldId from "./getGroupByFieldId";
import GroupTarget from "../../types/targets/GroupTarget";
import {builderActions} from "../../slices/builderSlice";
import {editorActions} from "../../slices/editorSlice";
import {BuilderDispatch, BuilderRootState} from "../../builderStore";

export default function copyFieldAction(fieldId: string) {
    return (dispatch: BuilderDispatch, getState: () => BuilderRootState) => {
        const elements = getElementsByRootState(getState());
        const group = getGroupByFieldId(elements, fieldId);
        const [fieldCopy, references] = createFieldCopy(elements, fieldId);
        const field = elements.fields[fieldId];

        if (!fieldCopy || !group || !field?.rowId) {
            return;
        }

        const target: GroupTarget = {
            type: 'group',
            groupId: group.id,
            position: group.rowIds.indexOf(field.rowId) + 1
        };

        dispatch(builderActions.addField({field: fieldCopy, target, references}));
        dispatch(editorActions.setActiveField(fieldCopy.id))
    };
}