import React from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/HeadlineNormalizedEntry";
import useFieldProp from "../../../hooks/element/useFieldProp";
import Rte from "../../../../common/components/rte/Rte";

type Props = {
    fieldId: string
}

function HeadlineEditable({fieldId}: Props) {
    const level = useFieldSelector<Type>(fieldId, field => field?.level);
    const [content, setContent] = useFieldProp<Type>({fieldId, property: 'content'});

    const rte = <Rte value={content} onChange={setContent} singleBlock/>

    switch (level) {
        case 1:
            return <h1>{rte}</h1>
        case 2:
            return <h2>{rte}</h2>
        case 3:
            return <h3>{rte}</h3>
        default:
            return null;
    }
}

export default React.memo(HeadlineEditable);