import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import styles from "./StyleToggle.module.scss";
import React from "react";

type Props = {
    icon: IconProp | string,
    value: boolean,
    onChange: (value: boolean) => void
}

export default function StyleToggle({icon, value, onChange}: Props) {
    return (
        <div className={classNames(styles.toggle, {[styles.active]: value})} onClick={() => onChange(!value)}>
            {typeof icon === 'string' ? icon : <FontAwesomeIcon icon={icon}/>}
        </div>
    );
}