import React from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {editorActions} from "../../slices/editorSlice";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    fieldId: string
}

function FieldConfigButton({fieldId}: Props) {
    const dispatch = useBuilderDispatch();

    return (
        <CornerButton position="top-right" icon={faCog} onClick={() => dispatch(editorActions.setPanel('right'))}>
            Bearbeiten
        </CornerButton>
    );
}

export default React.memo(FieldConfigButton);