import {v4 as uuid} from "uuid";
import ColorSet from "../types/ColorSet";
import Color from "../types/Color";

export default function createColorSet(name: string, isDefault?: boolean, colors?: Color[]): ColorSet {
    return {
        id: uuid(),
        name,
        'default': isDefault,
        colors: colors ?? []
    };
}
