import React from "react";
import {CustomPicker} from "react-color";
import {EditableInput, Hue, Saturation} from "react-color/lib/components/common";
import styles from "./ColorPicker.module.scss";

function ColorPicker({...props}) {
    return (
        <div className={styles.ColorPicker}>
            <div className={styles.pointers}>
                <div className={styles.saturation}>
                    <Saturation
                        {...props}
                        onChange={(c) => props.onChange(c)}
                    />
                </div>
                <div className={styles.hue}>
                    <Hue
                        {...props}
                        pointer={Pointer}
                        direction="vertical"
                        onChange={(c) => props.onChange(c)}
                    />
                </div>
            </div>
            <div className={styles.input}>
                <EditableInput
                    value={props.hex}
                    onChange={(c) => props.onChange(c)}
                />
            </div>
        </div>
    );
}

function Pointer() {
    return <div className={styles.pointer}/>;
}

export default CustomPicker(ColorPicker)