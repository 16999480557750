import useDesignerSelector from "../hooks/useDesignerSelector";
import List from "../../common/components/List";
import DesignListItem from "./DesignListItem";
import useDesignerDispatch from "../hooks/useDesignerDispatch";
import {designerActions} from "../designerSlice";

export default function DesignList() {
    const dispatch = useDesignerDispatch();
    const styleIds = useDesignerSelector(state => state.styleSetIds);

    return (
        <List
            withDefault
            add={{
                label: 'Design hinzufügen',
                callback: () => {
                    dispatch(designerActions.openModal('style/new'));
                }
            }}
        >
            {styleIds.map(styleId => <DesignListItem styleSetId={styleId} key={styleId}/>)}
        </List>
    );
}