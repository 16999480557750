import {useDraggable} from "@dnd-kit/core";
import DragSource from "../../types/drag/DragSource";

export default function useElementDroppable(source: DragSource) {
    return useDraggable({id: getId(source), data: source});
}

function getId(source: DragSource) {
    switch (source.type) {
        case "fixture":
            return source.type + ':' + source.fixtureId;
        case "field":
            return source.type + ':' + source.fieldId;
        case "group":
            return source.type + ':' + source.groupId;
    }
}