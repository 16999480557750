import React, {ReactNode} from "react";
import styles from "./Sets.module.scss";
import Button from "../../common/components/Button";
import classNames from "classnames";
import BootstrapCol from "../types/BootstrapCol";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

type Props = {
    title: string
    children: {
        title: string,
        withDefault?: boolean
        col: BootstrapCol,
        children: ReactNode
    }[]
    cancel?: () => void
    save?: () => void
    saving?: boolean
    loading?: boolean
}

export default function Sets({title, children, save, saving, cancel, loading}: Props) {
    return (
        <div className={styles.sets}>
            <h1 className={styles.title}>
                {title}
            </h1>
            <div className={styles.header}>
                <div className={styles.inside}>
                    <div className="row">
                        {children.map(child =>
                            <div className={"col-" + child.col} key={child.title}>
                                <h2>{child.title}</h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className={styles.body}>
                {loading ? <div className={styles.loader}><FontAwesomeIcon spin icon={faSpinner}/></div> : (
                    <div className="row h-100">
                        {children.map(child =>
                            <div key={child.title}
                                 className={classNames(
                                     "h-100 overflow-auto col-" + child.col,
                                     styles.list,
                                     {[styles.withDefault]: child.withDefault}
                                 )}>
                                {child.children}
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={styles.footer}>
                <div className={styles.inside}>
                    <Button className="btn-secondary" disabled={!cancel} onClick={cancel}>Abbrechen</Button>

                    <Button loading={saving} disabled={!save || saving || loading} onClick={save}>
                        Änderung speichern
                    </Button>
                </div>
            </div>
        </div>
    )
}