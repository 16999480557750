import IconButton from "./IconButton";
import React from "react";
import styles from "./CornerButton.module.scss";
import classNames from "classnames";

type Props = React.ComponentPropsWithoutRef<typeof IconButton> & {
    position: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
};

export default function CornerButton({position, ...rest}: Props) {
    let direction: React.ComponentProps<typeof IconButton>['direction'];

    switch (position) {
        case "top-left":
            direction = 'left';
            break;
        case "top-right":
            direction = 'right';
            break;
        case "bottom-left":
            direction = 'left';
            break;
        case "bottom-right":
            direction = 'right';
            break;
    }

    return (
        <IconButton {...rest} className={classNames(styles.button, styles[position])} direction={direction} size="sm"/>
    );
}
