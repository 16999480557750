import useBuilderSelector from "../useBuilderSelector";
import getElementsByRootState from "../../util/element/getElementsByRootState";
import {createSelector} from "@reduxjs/toolkit";
import {BuilderRootState} from "../../builderStore";

export default function useFieldSelector<F, TSelected = any>(fieldId: string, selector: (field: F | undefined) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean): TSelected {
    // @ts-ignore
    const reselect = createSelector(
        (state: BuilderRootState) => getElementsByRootState(state).fields[fieldId],
        selector,
    );

    return useBuilderSelector(reselect, equalityFn);
}