import * as React from "react";
import {ComponentPropsWithoutRef, useCallback, useEffect, useRef, useState} from "react";
import {Editor, EditorState, RichUtils} from "draft-js";
import {stateFromHTML} from "draft-js-import-html";
import toHtml from "../../util/toHtml";
import useDebounce from "../../hooks/useDebounce";
import styles from "./Rte.module.scss";
import "draft-js/dist/Draft.css";
import RteToolbar from "./RteToolbar";

type Props = {
    value: string
    onChange: (value: string) => void
    singleBlock?: boolean
    textAlignment?: ComponentPropsWithoutRef<typeof Editor>['textAlignment']
}

export default function Rte({value, onChange, singleBlock, textAlignment}: Props) {
    const [editorState, setEditorState] = useState(() => {
        return EditorState.set(EditorState.createWithContent(stateFromHTML(value)), {
            allowUndo: false,
        });
    });

    useEffect(() => {
        setEditorState((rteState: EditorState) => {
            const currentContent = toHtml(rteState.getCurrentContent(), !!singleBlock);

            if (currentContent !== value) {
                return EditorState.push(rteState, stateFromHTML(value), 'undo');
            } else {
                return rteState;
            }
        });
    }, [value, setEditorState, singleBlock]);

    const submit = useCallback((editorState: EditorState) => {
        onChange(toHtml(editorState.getCurrentContent(), !!singleBlock));
    }, [onChange, singleBlock]);

    const handleReturn = useCallback((e: React.KeyboardEvent<{}>, editorState: EditorState) => {
        if (singleBlock) {
            setEditorState(RichUtils.insertSoftNewline(editorState));
            return 'handled';
        }

        return 'not-handled';
    }, [singleBlock, setEditorState])


    useDebounce(editorState, submit, 500);

    const hasFocus = editorState.getSelection().getHasFocus();
    const editorRef = useRef<HTMLDivElement>(null);

    return (
        <div className={styles.editor}>
            {hasFocus && <RteToolbar editorState={editorState} onChange={setEditorState} editorRef={editorRef}/>}
            <div ref={editorRef}>
                <Editor
                    textAlignment={textAlignment}
                    editorState={editorState}
                    onChange={setEditorState}
                    onBlur={() => submit(editorState)}
                    handleReturn={handleReturn}
                />
            </div>
        </div>
    );
}

