import arrayRemove from "../../../common/util/arrayRemove";
import removeGroup from "./removeGroup";
import {PageNormalized} from "../../normalizer/normalizePage";

export default function removeContainer(pageNormalized: PageNormalized, containerId: string) {
    const container = pageNormalized.containers[containerId];
    const page = pageNormalized.pages[container?.pageId || ''];

    if (!container) return;

    container.groupIds.forEach(groupId => removeGroup(pageNormalized, groupId));
    container.pageId = undefined;
    arrayRemove(page?.containerIds || [], containerId);
    delete pageNormalized.containers[containerId]
}