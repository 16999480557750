import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {dragReducer} from "./slices/dragSlice";
import undoable, {combineFilters, excludeAction} from "redux-undo";
import {builderReducer} from "./slices/builderSlice";
import {editorActions, editorReducer} from "./slices/editorSlice";
import filterQuietAction from "../common/util/filterQuietAction";
import reduceUndoable from "./util/redux/reduceUndoable";
import {dataReducer} from "./slices/dataSlice";

export const builderStore = configureStore({
    reducer: {
        data: dataReducer,
        drag: dragReducer,
        undoable: reduceUndoable(undoable(combineReducers({
            editor: editorReducer,
            builder: builderReducer,
        }), {
            syncFilter: true,
            filter: combineFilters(
                filterQuietAction,
                excludeAction([
                    editorActions.setPanel.type,
                    editorActions.setActiveGroup.type,
                    editorActions.setActiveField.type,
                ])
            )
        })),
    },
    devTools: process.env.NODE_ENV === 'development'
});

export type BuilderStore = typeof builderStore;
export type BuilderDispatch = typeof builderStore.dispatch;
export type BuilderRootState = ReturnType<typeof builderStore.getState>;