import HeadlineStyle from "../../types/styles/HeadlineStyle";
import undefinify from "../../../common/util/undefinify";
import fromReference from "../../../common/util/fromReference";

export default function normalizeHeadlineStyle(style: HeadlineStyle) {
    const {font, ...rest} = style;
    return {
        styles: {
            [style.id]: undefinify({...rest, fontId: fromReference(font)}),
        }
    };
}