import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {MouseEvent} from "react";
import {DraftBlockType, EditorState, RichUtils} from "draft-js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type Props = {
    blockType: DraftBlockType,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
    title: string
    icon: IconProp
}

export default function RteBlockToggle({editorState, onChange, blockType, title, icon}: Props) {
    const onMouseDown = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(RichUtils.toggleBlockType(editorState, blockType));
    };

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{title}</Tooltip>}>
            <Button active={RichUtils.getCurrentBlockType(editorState) === blockType} onMouseDown={onMouseDown}>
                <FontAwesomeIcon icon={icon}/>
            </Button>
        </OverlayTrigger>
    );
}