import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import getFixture from "../../util/element/getFixture";

type Props = {
    fixtureId: string
}

function FieldFixture({fixtureId}: Props) {
    const fixture = getFixture(fixtureId);

    if (!fixture) {
        return null;
    }

    return (
        <div className="field-drag d-flex align-items-center text-nowrap overflow-hidden">
            <span className="pe-2"><FontAwesomeIcon icon={faArrowsAlt}/></span> {fixture.name}
        </div>
    );
}

export default React.memo(FieldFixture);