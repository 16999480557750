import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import TextNormalizedEntry from "../../types/element/fields/TextNormalizedEntry";

type Payload = {
    content: string,
    width?: number
}

export default function createText({content, width = COL_WIDTH}: Payload): TextNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'text',
        ...baseField,
        content,
    }
}