import React, {useRef} from "react";
import getPercent from "../../../common/util/getPercent";
import {COL_WIDTH} from "../../../../config";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import getGroupByFieldId from "../../util/element/getGroupByFieldId";
import FieldEditable from "./FieldEditable";
import FieldInside from "./FieldInside";

type Props = {
    fieldId: string
    editable?: boolean
}

function Field({fieldId, editable}: Props) {
    const ref = useRef<HTMLDivElement>(null);
    const width = useElementsSelector(elements => elements.fields[fieldId]?.width || COL_WIDTH);
    const groupWidth = useElementsSelector(elements => getGroupByFieldId(elements, fieldId)?.width || 0);

    return (
        <div ref={ref} style={{width: getPercent(width, groupWidth)}}>
            {editable ? <FieldEditable fieldId={fieldId} fieldRef={ref}/> : <FieldInside fieldId={fieldId}/>}
        </div>
    );
}

export default React.memo(Field);