import React from "react";
import useElementDroppable from "../../hooks/element/useElementDroppable";
import getPercent from "../../../common/util/getPercent";
import {COL_WIDTH} from "../../../../config";
import calcContainerLeftWidth from "../../util/element/calcContainerLeftWidth";
import useBuilderSelector from "../../hooks/useBuilderSelector";
import getElementsByRootState from "../../util/element/getElementsByRootState";

type Props = {
    containerId: string
}

function ContainerDropzone({containerId}: Props) {
    const {setNodeRef, isOver} = useElementDroppable({type: 'container', containerId});
    const leftWidth = useBuilderSelector(state => {
        const elements = getElementsByRootState(state);
        if (!state.drag.source) {
            return 0;
        }

        return calcContainerLeftWidth(elements, containerId);
    });

    if (!leftWidth) {
        return null;
    }

    return (
        <div style={{width: getPercent(leftWidth, COL_WIDTH)}}>
            <div
                ref={setNodeRef}
                style={{
                    opacity: isOver ? 1 : 0.5,
                    minHeight: 20
                }}
                className={"d-flex border h-100 align-items-center justify-content-center" + (isOver ? ' droppable' : '')}>
                + Add new group
            </div>
        </div>
    );
}

export default React.memo(ContainerDropzone);