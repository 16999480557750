import Fixture from "../types/element/fields/Fixture";
import createInput from "../factories/fields/createInput";
import createSubmitButton from "../factories/fields/createSubmitButton";

const formFields: Fixture[] = [
    {
        id: 'email-input',
        name: 'E-Mail Address',
        factory: () => createInput({inputType: 'email', label: 'E-Mail Address'})
    },
    {
        id: 'input-firstname',
        name: 'Firstname',
        factory: () => createInput({inputType: 'text', label: 'Firstname'})
    },
    {
        id: 'input-lastname',
        name: 'Lastname',
        factory: () => createInput({inputType: 'text', label: 'Lastname'})
    },
    {
        id: 'submit-button',
        name: 'Submit',
        factory: () => createSubmitButton({label: 'Submit'})
    },
];

export default formFields;