import {ContentState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";

export default function toHtml(contentState: ContentState, removeWrapTag: boolean): string {
    const html = stateToHTML(contentState)
        .replace(/(\r\n|\n|\r)/gm, '');

    if (removeWrapTag) {
        return html.replace(/^<([a-z]+)>(.*)<\/\1>$/, '$2');
    }

    return html;
}