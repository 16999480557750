import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import BaseTextColorSelectOption from "./BaseTextColorSelectOption";

type Props = {
    label: string
    styleType: "text" | "headline"
    styleProp: 'colorIndex' | 'linkColorIndex' | 'linkHoverColorIndex'
    styleId: string,
}

export default function BaseTextColorSelect({styleId, styleType, styleProp, label}: Props) {
    const dispatch = useDesignerDispatch();
    const colorSetId = useDesignerSelector(state => state.styleSets[state.activeStyleSetId ?? '']?.colorSetId);
    const colorIds = useDesignerSelector(state => state.colorSets[colorSetId ?? '']?.colorIds ?? []);
    const colorIndex = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.[styleProp] ?? 0);
    const onChange = (value: number) => {
        dispatch(designerActions.updateBaseTextStyle({styleId, styleType, values: {[styleProp]: value}}))
    };

    const isActive = (index: number) => {
        if (colorIndex > colorIds.length && index === colorIds.length - 1) {
            return true;
        }

        return index === colorIndex;
    }

    return (
        <>
            <label className="form-label small">{label}</label>
            <div className="d-flex flex-wrap">
                {colorIds?.map((colorId, i) => (
                    <BaseTextColorSelectOption colorId={colorId} active={isActive(i)} setActive={() => onChange(i)}/>
                ))}
            </div>
        </>
    );
}

