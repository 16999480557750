import React from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import copyFieldAction from "../../util/element/copyFieldAction";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    fieldId: string
}

function FieldCopyButton({fieldId}: Props) {
    const dispatch = useBuilderDispatch();

    return (
        <CornerButton position="bottom-left" icon={faCopy} onClick={() => dispatch(copyFieldAction(fieldId))}>
            Duplizieren
        </CornerButton>
    );
}

export default React.memo(FieldCopyButton);