import {useCallback} from "react";
import getFieldByRootState from "../../util/element/getFieldByRootState";
import Type from "../../types/element/fields/TextNormalizedEntry";
import {builderActions} from "../../slices/builderSlice";
import useBuilderDispatch from "../useBuilderDispatch";
import useFieldSelector from "./useFieldSelector";

type KeyOf<T> = Extract<keyof T, string>;

type Args<Field> = {
    fieldId: string
    property: KeyOf<Field>
}

export default function useFieldProp<Field>({fieldId, property}: Args<Field>): [string, (value: string) => void] {
    const dispatch = useBuilderDispatch();
    const value = useFieldSelector<Field>(fieldId, field => field ? field[property] : '');
    const onChange = useCallback((content: string) => {
        dispatch((dispatch, getState) => {
            const field = getFieldByRootState<Type>(getState(), fieldId);

            if (!field) {
                return;
            }

            // @ts-ignore
            const currentContent = field[property];

            if (currentContent !== content) {
                dispatch(builderActions.updateField({fieldId, update: {[property]: content}}));
            }
        })
    }, [fieldId, dispatch, property]);

    return [value, onChange]
}