import DesignerState from "../types/DesignerState";

export default function getActiveStyleId(state: DesignerState) {
    const styleSet = state.styleSets[state.activeStyleSetId || ''];

    switch (state.activeStyleType) {
        case "headline1":
            return styleSet?.headline1StyleId;
        case "headline2":
            return styleSet?.headline2StyleId;
        case "headline3":
            return styleSet?.headline3StyleId;
        case "headline4":
            return styleSet?.headline4StyleId;
        case "headline5":
            return styleSet?.headline5StyleId;
        case "text1":
            return styleSet?.text1StyleId;
        case "text2":
            return styleSet?.text2StyleId;
        case "text3":
            return styleSet?.text3StyleId;
        case "unordered-list":
            return styleSet?.unorderedListStyleId;
        case "ordered-list":
            return styleSet?.orderedListStyleId;
    }
}