import {editorActions} from "../../slices/editorSlice";
import {BuilderDispatch, BuilderRootState} from "../../builderStore";

export default function clearActiveAction() {
    return (dispatch: BuilderDispatch, getState: () => BuilderRootState) => {
        const state = getState();
        const currentActive = state.undoable.present.editor.activeFieldId || state.undoable.present.editor.activeGroupId || state.undoable.present.editor.panel;

        if (currentActive) {
            dispatch(editorActions.setActiveField(undefined));
        }
    };
}