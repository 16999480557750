import normalizeStyleSets from "../normalizer/normalizeStyleSets";
import normalizeColorSet from "../../colorer/normalizer/normalizeColorSet";
import normalizeFonts from "../normalizer/normalizeFonts";

export const designerModals = <const>[
    'style/new', 'style/edit'
];

export const styleTypes = <const>[
    'headline1', 'headline2', 'headline3', 'headline4', 'headline5',
    'text1', 'text2', 'text3', 'unordered-list', 'ordered-list'
];

export type DesignerModal = typeof designerModals[number];
export type StyleType = typeof styleTypes[number];

export default interface DesignerState extends ReturnType<typeof normalizeStyleSets>,
    ReturnType<typeof normalizeColorSet>,
    ReturnType<typeof normalizeFonts> {
    initialized: boolean
    activeStyleType: StyleType
    activeStyleSetId?: string,
    defaultColorSetId: string,
    defaultFontId: string,
    modal?: DesignerModal,
    fontIds: string[]
}