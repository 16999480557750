import BuilderState from "../../types/element/BuilderState";
import calcRowWidth from "./calcRowWidth";
import resizeField from "./resizeField";

export default function resizeGroup(elements: BuilderState, groupId: string, width: number) {
    const group = elements.groups[groupId];

    if (!group) {
        return;
    }

    //if scaled down, we need to re calc fields width
    if (group.width > width) {
        group.rowIds.forEach(rowId => {
            let diff = calcRowWidth(elements, rowId) - width;
            const fields = [...elements.rows[rowId]?.fieldIds || []]

            fields.reverse().forEach(fieldId => {
                const field = elements.fields[fieldId];

                if (field && diff > 0) {
                    const newWidth = Math.max(1, field.width - diff);
                    diff = diff - (field.width - newWidth);
                    resizeField(elements, fieldId, newWidth);
                    field.width = newWidth;
                }
            });
        })
    }

    group.width = width;
}