import React, {useState} from "react";
import useFieldSelector from "../../../hooks/element/useFieldSelector";
import Type from "../../../types/element/fields/ImageNormalizedEntry";

type Props = {
    fieldId: string
}

function Image({fieldId}: Props) {
    const src = useFieldSelector<Type>(fieldId, field => field?.src);
    const [ratio, setRatio] = useState<number>();

    return (
        <div className="position-relative overflow-hidden">
            <div style={ratio ? {paddingTop: 100 / ratio + '%'} : undefined}/>
            <img ref={el => setRatio(el ? el.width / el.height : undefined)} src={src}
                 className="w-100 position-absolute top-0 start-0" alt={fieldId}/>
        </div>
    );
}

export default React.memo(Image);