import useDesignerSelector from "../../hooks/useDesignerSelector";
import classNames from "classnames";
import styles from "./BaseTextColorSelectOption.module.scss";

type Props = {
    colorId: string
    active: boolean,
    setActive: () => void
}

export default function BaseTextColorSelectOption({colorId, active, setActive}: Props) {
    const color = useDesignerSelector(state => state.colors[colorId]);

    if(!color) {
        return null;
    }

    return (
        <div className={classNames(styles.color, {[styles.active]: active})} onClick={setActive}>
            <div className={styles.inside} style={{backgroundColor: color.hex}}/>
        </div>
    );
}

