import Group from "../types/Group";
import merge from "ts-deepmerge";
import normalizeRow from "./normalizedRow";
import undefinify from "../../common/util/undefinify";

export type GroupNormalized = ReturnType<typeof normalizeGroup>;

export default function normalizeGroup(group: Group, containerId?: string) {
    const {rows, position, ...rest} = group;

    return merge({
        groups: {
            [group.id]: undefinify({
                ...rest,
                containerId,
                rowIds: rows.map(row => row.id)
            })
        }
    }, ...rows.map(row => normalizeRow(row, group.id)))
}