import normalizeStyleSets from "./normalizeStyleSets";
import StyleSet from "../types/StyleSet";
import denormalizeStyleSet from "./denormalizeStyleSet";

export default function denormalizeStyleSets(normalized: ReturnType<typeof normalizeStyleSets>): StyleSet[] {
    return normalized.styleSetIds.flatMap((styleSetId, i) => {
        const styleSet = denormalizeStyleSet(normalized, styleSetId, i);
        return styleSet ? [styleSet] : [];
    });
}
