import Fixture from "../types/element/fields/Fixture";
import createHeadline from "../factories/fields/createHeadline";
import createText from "../factories/fields/createText";
import createImage from "../factories/fields/createImage";
import createLine from "../factories/fields/createLine";

const contentFields: Fixture[] = [
    {
        id: 'headline-one',
        name: 'Headline One',
        factory: () => createHeadline({level: 1, content: "Headline One"})
    },
    {
        id: 'headline-two',
        name: 'Headline Two',
        factory: () => createHeadline({level: 2, content: "Headline Two"})
    },
    {
        id: 'headline-three',
        name: 'Headline Three',
        factory: () => createHeadline({level: 3, content: "Headline Three"})
    },
    {
        id: 'text',
        name: 'Text',
        factory: () => createText({
            content: "<p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy" +
                " eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos " +
                "et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus " +
                "est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.</p>"
        })
    },
    {
        id: 'image',
        name: 'Image',
        factory: () => createImage({src: 'https://picsum.photos/800/300'})
    },
    {
        id: 'line',
        name: 'Line',
        factory: () => createLine()
    }
];

export default contentFields;