import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import StyleToggle from "./StyleToggle";
import {faAlignCenter, faAlignJustify, faAlignLeft, faAlignRight} from "@fortawesome/free-solid-svg-icons";
import BaseTextStyle from "../../types/styles/BaseTextStyle";

type Props = {
    styleType: "text" | "headline"
    styleId: string
}

export default function AlignSelect({styleId, styleType}: Props) {
    const dispatch = useDesignerDispatch();
    const align = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.align ?? 'left');
    const onChange = (align: BaseTextStyle['align']) => {
        dispatch(designerActions.updateBaseTextStyle({styleId, styleType, values: {align}}))
    };

    return (
        <>
            <label className="form-label small">Ausrichtung</label>
            <div className="d-flex">
                <StyleToggle icon={faAlignLeft} value={align === 'left'} onChange={() => onChange('left')}/>
                <StyleToggle icon={faAlignCenter} value={align === 'center'} onChange={() => onChange('center')}/>
                <StyleToggle icon={faAlignRight} value={align === 'right'} onChange={() => onChange('right')}/>
                <StyleToggle icon={faAlignJustify} value={align === 'justify'} onChange={() => onChange('justify')}/>
            </div>
        </>
    );
}

