import React from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {builderActions} from "../../slices/builderSlice";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../common/components/IconButton";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    fieldId: string
    className?: string
    direction?: React.ComponentProps<typeof IconButton>['direction']
}

function FieldDeleteButton({fieldId, direction, className}: Props) {
    const dispatch = useBuilderDispatch();
    const onDeleteClick = () => {
        dispatch(builderActions.removeField({fieldId}))
    }

    return (
        <CornerButton position="bottom-right" color="red" icon={faTrashAlt} onClick={onDeleteClick}>
            Löschen
        </CornerButton>
    );
}

export default React.memo(FieldDeleteButton);