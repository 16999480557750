import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faRedoAlt} from "@fortawesome/free-solid-svg-icons";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {ActionCreators} from "redux-undo";
import useBuilderSelector from "../../hooks/useBuilderSelector";

export default function RedoButton() {
    const dispatch = useBuilderDispatch();

    const disabled = useBuilderSelector(state => state.undoable.future.length === 0);
    const redo = () => dispatch(ActionCreators.redo());

    return <button disabled={disabled} className="btn btn-link" onClick={redo}><FontAwesomeIcon icon={faRedoAlt}/>
    </button>
}