import React from "react";
import useElementDroppable from "../../hooks/element/useElementDroppable";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import useBuilderSelector from "../../hooks/useBuilderSelector";

type Props = {
    groupId: string
    position: number
};

function GroupDropzone({groupId, position}: Props) {
    const {setNodeRef, isOver} = useElementDroppable({type: 'group', groupId, position});
    const isLast = useElementsSelector(elements => elements.groups[groupId]?.rowIds.length === position);
    const isHidden = useBuilderSelector(state => {
        const source = state.drag.source;

        return !source || source.type === 'group';
    });

    if (isHidden) {
        return null;
    }

    return (
        <div className="position-relative">
            <div ref={setNodeRef}
                 style={{
                     opacity: isOver ? 1 : 0.5,
                     height: 24,
                     bottom: isLast ? -24 : 0,
                     left: 0
                 }}
                 className={"position-absolute w-100" + (isOver ? ' droppable' : '')}>
            </div>
        </div>
    );
}

export default React.memo(GroupDropzone);