import React from "react";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import useElementDragable from "../../hooks/element/useElementDragable";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    fieldId: string
    fieldRef: React.RefObject<HTMLDivElement>
}

function FieldMoveButton({fieldId, fieldRef}: Props) {
    const {attributes, listeners, setNodeRef} = useElementDragable({type: 'field', fieldId});

    if (fieldRef.current) {
        setNodeRef(fieldRef.current);
    }

    return (
        <CornerButton color="lightblue" position="top-left" icon={faArrowsAlt} {...attributes} {...listeners}>
            Verschieben
        </CornerButton>
    );
}

export default React.memo(FieldMoveButton);