import {FormEvent, useState} from "react";
import useColorerDispatch from "../../hooks/useColorerDispatch";
import {colorerActions} from "../../colorerSlice";
import createColorSet from "../../factories/createColorSet";
import useColorerSelector from "../../hooks/useColorerSelector";
import ColorSetForm from "../ColorSetForm";
import FormModal from "../../../common/components/FormModal";

export default function ColorSetNewModal() {
    const dispatch = useColorerDispatch();
    const show = useColorerSelector(state => state.modal === 'color-set/new');
    const [name, setName] = useState('');
    const [isDefault, setDefault] = useState(false);

    if (!show) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name) return;

        const colorSet = createColorSet(name, isDefault);
        dispatch(colorerActions.addColorSet(colorSet));
        dispatch(colorerActions.setActiveColorSetId(colorSet.id));
        cancel();
    }

    const cancel = () => {
        setName('');
        setDefault(false);
        dispatch(colorerActions.closeModal());
    };

    return (
        <FormModal title="Neues Set hinzufügen" submit={name ? submit : undefined} cancel={cancel}>
            <ColorSetForm
                name={name} setName={setName}
                default={isDefault} setDefault={setDefault}
                submit={submit}/>
        </FormModal>
    )
}