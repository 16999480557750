import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import resizeGroup from "../util/element/resizeGroup";
import BuilderState from "../types/element/BuilderState";
import FieldNormalizedEntry from "../types/element/fields/FieldNormalizedEntry";
import ElementTarget from "../types/targets/ElementTarget";
import addField from "../util/element/addField";
import QuietableAction from "../../common/types/QuietableAction";
import moveField from "../util/element/moveField";
import removeField from "../util/element/removeField";
import resizeField from "../util/element/resizeField";
import removeGroup from "../util/element/removeGroup";
import moveGroup from "../util/element/moveGroup";
import ContainerTarget from "../types/targets/ContainerTarget";
import PageTarget from "../types/targets/PageTarget";
import GroupNormalizedEntry from "../types/element/GroupNormalizedEntry";
import addGroup from "../util/element/addGroup";
import Page from "../types/Page";
import normalizePage from "../normalizer/normalizePage";
import merge from "ts-deepmerge";
import appendElements from "../util/element/appendElements";
import createBuilderState from "../factories/createBuilderState";
import {PreparedTarget} from "../util/element/prepareTarget";
import normalizeContainer from "../normalizer/normalizeContainer";
import mergeDraftState from "../../common/util/mergeDraftState";
import normalizeGroup from "../normalizer/normalizeGroup";
import normalizeRow from "../normalizer/normalizedRow";
import normalizedField from "../normalizer/normalizedField";
import calcContainerLeftWidth from "../util/element/calcContainerLeftWidth";

const builderSlice = createSlice({
    name: 'builder',
    initialState: createBuilderState(),
    reducers: {
        init: (state: BuilderState, {payload: page}: PayloadAction<Page>) => {
            return merge(state, normalizePage(page));
        },
        addGroup: (state: BuilderState, {payload}: PayloadAction<{ group: GroupNormalizedEntry, target: PageTarget | ContainerTarget, references: BuilderState }>) => {
            const {group, target, references} = payload;
            appendElements(state, references);
            addGroup(state, group, target);
        },
        resizeGroup: (state: BuilderState, {payload}: QuietableAction<{ groupId: string, width: number }>) => {
            const {groupId, width} = payload;
            resizeGroup(state, groupId, width);
        },
        removeGroup: (state: BuilderState, {payload}: QuietableAction<{ groupId: string, keepReference?: boolean }>) => {
            const {groupId, keepReference} = payload;
            removeGroup(state, groupId, keepReference);
        },
        moveGroup: (state: BuilderState, {payload}: PayloadAction<{ groupId: string, target: PageTarget | ContainerTarget }>) => {
            const {groupId, target} = payload;
            moveGroup(state, groupId, target);
        },
        addFieldNew: (state: BuilderState, {payload: target}: PayloadAction<PreparedTarget>) => {
            let rowId: string;

            switch (target.type) {
                case 'page': {
                    mergeDraftState(state, normalizeContainer(target.container));
                    const page = state.pages[target.pageId];
                    const position = target.position;
                    const container = state.containers[target.container.id];
                    if (!page || !container) return;

                    container.pageId = page.id;
                    if (typeof position === "undefined" || position > page.containerIds.length) {
                        page.containerIds.push(container.id);
                    } else {
                        page.containerIds.splice(position, 0, container.id);
                    }
                    break;
                }
                case 'container': {
                    mergeDraftState(state, normalizeGroup(target.group));
                    const container = state.containers[target.containerId];
                    const group = state.groups[target.group.id];
                    const field = state.fields[target.fieldId];
                    if (!group || !container || !field) return;

                    const leftWidth = calcContainerLeftWidth(state, container.id);

                    if (!leftWidth) {
                        console.error('No width left for adding new group');
                        return;
                    }

                    //we need to resize group and field if group width is to large
                    if (group.width > leftWidth) {
                        group.width = leftWidth;
                        field.width = leftWidth;
                    }

                    group.containerId = container.id;
                    container.groupIds.push(group.id);

                    break;
                }
                case 'group':
                    mergeDraftState(state, normalizeRow(target.row));
                    break;
                case 'row':
                    mergeDraftState(state, normalizedField(target.field));
                    break;
            }

            //   const leftWidth = calcRowLeftWidth(state, rowId);
            //  field.rowId = row.id;
            // row.fieldIds.push(field.id);
        },
        addField: (state: BuilderState, {payload}: PayloadAction<{ field: FieldNormalizedEntry, target: ElementTarget, references?: BuilderState }>) => {
            const {field, target, references} = payload;
            references && appendElements(state, references);
            addField(state, field, target);
        },
        moveField: (state: BuilderState, {payload}: PayloadAction<{ fieldId: string, target: ElementTarget }>) => {
            const {fieldId, target} = payload;
            moveField(state, fieldId, target);
        },
        resizeField: (state: BuilderState, {payload}: QuietableAction<{ fieldId: string, width: number }>) => {
            const {fieldId, width} = payload;
            resizeField(state, fieldId, width);
        },
        removeField: (state: BuilderState, {payload}: QuietableAction<{ fieldId: string, keepReference?: boolean }>) => {
            const {fieldId, keepReference} = payload;
            removeField(state, fieldId, keepReference);
        },
        revert: (state: BuilderState, {payload}: QuietableAction<{ state: BuilderState }>) => {
            return payload.state;
        },
        updateField: (state: BuilderState, {payload}: PayloadAction<{ fieldId: string, update: Partial<FieldNormalizedEntry> }>) => {
            const {fieldId, update} = payload;
            const field = state.fields[fieldId];

            if (!field) {
                return;
            }

            // @ts-ignore
            state.fields[fieldId] = {...field, ...update};
        }
    },
});

export const builderActions = builderSlice.actions;
export const builderReducer = builderSlice.reducer;