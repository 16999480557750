import {MouseEvent as ReactMouseEvent, RefObject, useEffect, useRef} from "react";

type Args = {
    ref: RefObject<HTMLElement>
    min: number
    max: number
    width: number
    cols: number
    onResize?: (width: number) => void
    onResizeStart?: () => void
    onResizeEnd?: (width: number) => void
}

export default function useColResize({ref, width, min, cols, max, onResizeStart, onResize, onResizeEnd}: Args) {
    const widthRef = useRef(width);

    useEffect(() => {
        widthRef.current = width;
    }, [width]);

    const onMouseDown = (e: ReactMouseEvent<HTMLElement>) => {
        onResizeStart && onResizeStart();
        const start = e.clientX;
        let newWidth = width;

        const resize = (e: MouseEvent) => {
            const el = ref.current;
            const parent = el?.parentElement;

            if (!el || !parent) {
                return;
            }

            const steps = parent.clientWidth / cols;
            const diff = start - e.clientX;

            newWidth = Math.min(max, Math.max(min, width - Math.round(diff / steps)));

            if (widthRef.current !== newWidth && onResize) {
                onResize(newWidth);
            }
        }

        document.addEventListener('mousemove', resize);
        document.addEventListener('mouseup', () => {
            document.removeEventListener('mousemove', resize);
            onResizeEnd && onResizeEnd(newWidth);

        }, {once: true});
    };

    return {ref, listeners: {onMouseDown}}

}