import useColorerSelector from "../hooks/useColorerSelector";
import styles from "./ColorPreview.module.scss";
import getActiveColorId from "../util/getActiveColorId";

export default function ColorPreview() {
    const colorId = useColorerSelector(getActiveColorId);
    const hex = useColorerSelector(state => state.colors[colorId || '']?.hex);
    const description = useColorerSelector(state => state.colors[colorId || '']?.description);

    if (!colorId) {
        return null;
    }

    return (
        <div>
            {!!description && (
                <dl>
                    <dt>Verwendungszwck</dt>
                    <dd>{description}</dd>
                </dl>
            )}
            <div className={styles.preview} style={{backgroundColor: hex}}/>
        </div>
    );
}