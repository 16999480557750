import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import NumberSelect from "./NumberSelect";

type Props = {
    styleType: "text" | "headline"
    styleId: string
}

export default function LetterSpacingForm({styleId, styleType}: Props) {
    const dispatch = useDesignerDispatch();
    const letterSpacing = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.letterSpacing);
    const onChange = (letterSpacing: number) => {
        dispatch(designerActions.updateBaseTextStyle({styleId, styleType, values: {letterSpacing}}))
    };

    return (
        <>
            <label className="form-label small">Zeichenabstand</label>
            <NumberSelect className="form-select-sm" min={-1} max={2} step={0.1} value={letterSpacing ?? 0} onChange={onChange}/>
        </>
    );
}

