import BuilderState from "../../types/element/BuilderState";
import FieldNormalizedEntry from "../../types/element/fields/FieldNormalizedEntry";
import ElementTarget from "../../types/targets/ElementTarget";
import RowNormalizedEntry from "../../types/element/RowNormalizedEntry";
import createRowNormalized from "../../factories/createRowNormalized";
import addRow from "./addRow";
import calcRowLeftWidth from "./calcRowLeftWidth";

export default function addField(elements: BuilderState, field: FieldNormalizedEntry, target: ElementTarget) {
    let row: RowNormalizedEntry | undefined;

    if (target.type !== "row") {
        row = createRowNormalized();
        addRow(elements, row, target);
    } else {
        row = elements.rows[target.rowId];
        if (!row) return;
    }

    const leftWidth = calcRowLeftWidth(elements, row.id);

    if (!leftWidth) {
        console.error('No left width for adding new field');
        return;
    }

    //we need to resize field if field width is to large
    if (field.width > leftWidth) {
        field.width = leftWidth;
    }

    // @ts-ignore
    elements.fields[field.id] = field;
    field.rowId = row.id;
    row.fieldIds.push(field.id);
}