import normalizeStyleSet from "./normalizeStyleSet";
import StyleSet from "../types/StyleSet";
import denormalizeTextStyle from "./styles/denormalizeTextStyle";
import denormalizeHeadlineStyle from "./styles/denormalizeHeadlineStyle";
import denormalizeUnorderedListStyle from "./styles/denormalizeUnorderedListStyle";
import denormalizeOrderedListStyle from "./styles/denormalizeOrderedListStyle";
import createReference from "../../common/util/createReference";

export default function denormalizeStyleSet(normalized: ReturnType<typeof normalizeStyleSet>, styleSetId: string, position?: number): StyleSet | undefined {
    const styleSet = normalized.styleSets[styleSetId];
    if (!styleSet) {
        console.error('StyleSet could not be denormalized. StyleSet not found:', {styleSetId, normalized})
        return undefined;
    }

    const {
        colorSetId,
        headline1StyleId,
        headline2StyleId,
        headline3StyleId,
        headline4StyleId,
        headline5StyleId,
        text1StyleId,
        text2StyleId,
        text3StyleId,
        orderedListStyleId,
        unorderedListStyleId,
        ...rest
    } = styleSet;

    const headline1Style = denormalizeHeadlineStyle(normalized, headline1StyleId);
    const headline2Style = denormalizeHeadlineStyle(normalized, headline2StyleId);
    const headline3Style = denormalizeHeadlineStyle(normalized, headline3StyleId);
    const headline4Style = denormalizeHeadlineStyle(normalized, headline4StyleId);
    const headline5Style = denormalizeHeadlineStyle(normalized, headline5StyleId);
    const text1Style = denormalizeTextStyle(normalized, text1StyleId);
    const text2Style = denormalizeTextStyle(normalized, text2StyleId);
    const text3Style = denormalizeTextStyle(normalized, text3StyleId);
    const orderedListStyle = denormalizeOrderedListStyle(normalized, orderedListStyleId);
    const unorderedListStyle = denormalizeUnorderedListStyle(normalized, unorderedListStyleId);

    if (!headline1Style || !headline2Style || !headline3Style || !headline4Style || !headline5Style ||
        !text1Style || !text2Style || !text3Style || !unorderedListStyle || !orderedListStyle) {
        console.error('StyleSet could not be denormalized. At least one relation is undefined:', {
            normalized, styleSetId,
            headline1Style, headline2Style, headline3Style, headline4Style, headline5Style,
            text1Style, text2Style, text3Style, unorderedListStyle, orderedListStyle
        });
        return undefined
    }

    return {
        ...rest,
        position,
        headline1Style,
        headline2Style,
        headline3Style,
        headline4Style,
        headline5Style,
        text1Style,
        text2Style,
        text3Style,
        orderedListStyle,
        unorderedListStyle,
        colorSet: createReference(colorSetId)
    };
}
