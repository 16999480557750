import React from "react";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import useElementDragable from "../../hooks/element/useElementDragable";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    groupId: string
    groupRef: React.RefObject<HTMLDivElement>
}

function GroupMoveButton({groupId, groupRef}: Props) {
    const {attributes, listeners, setNodeRef} = useElementDragable({type: 'group', groupId});

    if (groupRef.current) {
        setNodeRef(groupRef.current);
    }

    return (
        <CornerButton color="violet" position="top-left" icon={faArrowsAlt} {...attributes} {...listeners}>
            Verschieben
        </CornerButton>
    );
}

export default React.memo(GroupMoveButton);