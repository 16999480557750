import {Button, OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {MouseEvent} from "react";
import {DraftInlineStyleType, EditorState, RichUtils} from "draft-js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

type Props = {
    style: DraftInlineStyleType,
    editorState: EditorState,
    onChange: (editorState: EditorState) => void
    title: string
    icon: IconProp
}

export default function RteStyleToggle({editorState, onChange, style, title, icon}: Props) {
    const onMouseDown = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(RichUtils.toggleInlineStyle(editorState, style));
    };

    return (
        <OverlayTrigger placement="top" overlay={<Tooltip>{title}</Tooltip>}>
            <Button active={editorState.getCurrentInlineStyle().has(style)} onMouseDown={onMouseDown}>
                <FontAwesomeIcon icon={icon}/>
            </Button>
        </OverlayTrigger>
    );
}