import BuilderState from "../../types/element/BuilderState";
import RowNormalizedEntry from "../../types/element/RowNormalizedEntry";
import PageTarget from "../../types/targets/PageTarget";
import ContainerTarget from "../../types/targets/ContainerTarget";
import GroupTarget from "../../types/targets/GroupTarget";
import GroupNormalizedEntry from "../../types/element/GroupNormalizedEntry";
import createGroupNormalized from "../../factories/createGroupNormalized";
import addGroup from "./addGroup";

export default function addRow(elements: BuilderState, row: RowNormalizedEntry, target: PageTarget | ContainerTarget | GroupTarget) {
    let group: GroupNormalizedEntry | undefined;
    let position: number | undefined;

    if (target.type !== "group") {
        group = createGroupNormalized();
        addGroup(elements, group, target);
    } else {
        position = target.position;
        group = elements.groups[target.groupId];
        if (!group) return;
    }

    // @ts-ignore
    elements.rows[row.id] = row;
    row.groupId = group.id;

    if (typeof position === "undefined" || position > group.rowIds.length) {
        group.rowIds.push(row.id);
    } else {
        group.rowIds.splice(position, 0, row.id);
    }
}
