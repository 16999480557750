import React from "react";
import useFieldProp from "../../../hooks/element/useFieldProp";
import InputNormalizedEntry from "../../../types/element/fields/InputNormalizedEntry";
import SubmitButtonNormalizedEntry from "../../../types/element/fields/SubmitButtonNormalizedEntry";
import Rte from "../../../../common/components/rte/Rte";


type Props = {
    fieldId: string,
    textAlign?: 'left' | 'center' | 'right'
}

function LabelRte({fieldId, textAlign}: Props) {
    const [label, setLabel] = useFieldProp<InputNormalizedEntry | SubmitButtonNormalizedEntry>({
        fieldId,
        property: 'label'
    })

    return (
        <Rte value={label} onChange={setLabel} textAlignment={textAlign} singleBlock/>
    );
}

export default React.memo(LabelRte);