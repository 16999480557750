import useDesignerSelector from "../hooks/useDesignerSelector";
import useDesignerDispatch from "../hooks/useDesignerDispatch";
import ListItem from "../../common/components/ListItem";
import {designerActions} from "../designerSlice";

type Props = {
    styleSetId: string
}

export default function DesignListItem({styleSetId}: Props) {
    const dispatch = useDesignerDispatch();
    const name = useDesignerSelector(state => state.styleSets[styleSetId]?.name);
    const readonly = useDesignerSelector(state => !!state.styleSets[styleSetId]?.readonly);
    const isDefault = useDesignerSelector(state => !!state.styleSets[styleSetId]?.default);
    const isActive = useDesignerSelector(state => state.activeStyleSetId === styleSetId);

    const edit = () => {
        dispatch(designerActions.openModal('style/edit'));
    }

    const activate = () => {
        dispatch(designerActions.setActiveStyleId(styleSetId));
    }

    const remove = () => {
        dispatch(designerActions.removeStyleSet(styleSetId));
    }

    return (
        <ListItem
            withDefault
            active={isActive}
            default={isDefault}
            edit={!readonly ? edit : undefined}
            activate={activate}
            remove={!readonly ? remove : undefined}
        >
            {name}
        </ListItem>
    )
}