import React, {RefObject} from "react";
import useBuilderSelector from "../../hooks/useBuilderSelector";
import FieldDeleteButton from "./FieldDeleteButton";
import FieldMoveButton from "./FieldMoveButton";
import FieldResizeButton from "./FieldResizeButton";
import FieldConfigButton from "./FieldConfigButton";
import FieldCopyButton from "./FieldCopyButton";
import FieldInside from "./FieldInside";
import classNames from "classnames";
import styles from "./FieldEditable.module.scss"
import useFieldActive from "../../hooks/element/useFieldActive";

type Props = {
    fieldId: string
    fieldRef: RefObject<HTMLDivElement>
}

function FieldEditable({fieldId, fieldRef}: Props) {
    const [active, setActive] = useFieldActive(fieldId);
    const isDragging = useBuilderSelector(state => state.drag.active);

    return (
        <div className={classNames(styles.wrapper, {[styles.active]: active, 'pe-none': isDragging})}
             onMouseDown={e => {
                 if (!isDragging) {
                     e.stopPropagation();
                     setActive(true);
                 }
             }}>
            <div className={styles.control}>
                {active && (
                    <>
                        <FieldDeleteButton fieldId={fieldId}/>
                        <FieldConfigButton fieldId={fieldId}/>
                        <FieldCopyButton fieldId={fieldId}/>
                        <FieldMoveButton fieldId={fieldId} fieldRef={fieldRef}/>
                        <FieldResizeButton fieldId={fieldId} fieldRef={fieldRef}/>
                    </>
                )}
            </div>
            <div className={styles.inside}>
                <FieldInside fieldId={fieldId} editable/>
            </div>
        </div>
    );
}

export default React.memo(FieldEditable);