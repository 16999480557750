import React from "react";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";
import GroupInside from "./GroupInside";
import styles from "./GroupEditable.module.scss"
import classNames from "classnames";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    groupId: string
}

export default function GroupDragOverlay({groupId}: Props) {
    return (
        <div className={classNames(styles.wrapper, styles.active)}>
            <div className={classNames('bg-white', styles.control)}>
                <CornerButton color="violet" position="top-left" icon={faArrowsAlt}/>
            </div>
            <div className={classNames(styles.inside)}>
                <GroupInside groupId={groupId} editable/>
            </div>
        </div>
    );
}