import {AnyAction, EnhancedStore} from "@reduxjs/toolkit";

export type Connectors<State> = {
    [key: string]: [(state: State) => string | undefined, (value: string | undefined) => AnyAction]
}

type Values = {
    [key: string]: string | undefined
}

export default function syncUrlParams<State>(store: EnhancedStore, connectors: Connectors<State>) {
    const params = new URLSearchParams(window.location.search);
    const names = Object.keys(connectors);

    let currentValues: Values = {};

    names.forEach(name => {
        const action = connectors[name][1];
        const value = params.get(name);
        currentValues[name] = value === null ? undefined : value;

        if (value !== undefined) {
            store.dispatch(action(currentValues[name]));
        }
    })

    return store.subscribe(() => {
        let updateNames = [];

        names.forEach(name => {
            const reducer = connectors[name][0];
            const value = reducer(store.getState());

            if (currentValues[name] !== value) {
                currentValues[name] = value;
                updateNames.push(name);
            }
        });

        if (!updateNames.length) {
            return;
        }

        const params = new URLSearchParams(window.location.search);

        names.forEach(name => {
            const value = currentValues[name];

            if (value === undefined) {
                params.delete(name);
            } else {
                params.set(name, value);
            }
        });

        const toString = params.toString();
        window.history.replaceState({}, '', window.location.pathname + (toString ? '?' + toString : ''));
    });
}
