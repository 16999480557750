import React from "react";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {editorActions} from "../../slices/editorSlice";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    groupId: string
}

function GroupConfigButton({groupId}: Props) {
    const dispatch = useBuilderDispatch();

    return (
        <CornerButton icon={faCog} position="top-right" onClick={() => dispatch(editorActions.setPanel('right'))}>
            Bearbeiten
        </CornerButton>
    );
}

export default React.memo(GroupConfigButton);