import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUndoAlt} from "@fortawesome/free-solid-svg-icons";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import {ActionCreators} from 'redux-undo'
import useBuilderSelector from "../../hooks/useBuilderSelector";

export default function UndoButton() {
    const dispatch = useBuilderDispatch();
    const disabled = useBuilderSelector(state => state.undoable.past.length === 0);

    const undo = () => dispatch(ActionCreators.undo());

    return (
        <button disabled={disabled} className="btn btn-link" onClick={undo}>
            <FontAwesomeIcon icon={faUndoAlt}/>
        </button>
    );
}