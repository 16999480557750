import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import styles from "./IconButton.module.scss";
import classNames from "classnames";

type Props = {
    icon: IconProp,
    direction?: 'left' | 'right'
    color?: 'blue' | 'lightblue' | 'red' | 'violet'
    size?: 'lg' | 'sm'
} & React.ComponentProps<"button">

export default function IconButton(
    {className, children, icon, direction = 'right', color = 'blue', size, ...rest}: Props
) {
    return (
        <button className={classNames(
            className,
            styles.button,
            styles['color-' + color],
            styles['direction-' + direction],
            size && styles['size-' + size],
        )} {...rest}>
            {children && <span className={styles.title}>{children}</span>}
            <span className={styles.icon}>
                <FontAwesomeIcon icon={icon}/>
            </span>
        </button>
    );
}