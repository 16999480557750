import DesignerState from "../types/DesignerState";

export default function createDesignerState(): DesignerState {
    return {
        colorSets: {},
        fontIds: [],
        fonts: {},
        defaultColorSetId: "",
        defaultFontId: "",
        styleSetIds: [],
        styleSets: {},
        styles: {},
        colors: {},
        activeStyleType: 'headline1',
        initialized: false
    }
}