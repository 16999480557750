import Row from "../types/Row";
import merge from "ts-deepmerge";
import normalizedField from "./normalizedField";
import undefinify from "../../common/util/undefinify";

export type RowNormalized = ReturnType<typeof normalizeRow>;

export default function normalizeRow(row: Row, groupId?: string) {
    const {fields, position, ...rest} = row;

    return merge({
        rows: {
            [row.id]: undefinify({
                ...rest,
                groupId,
                fieldIds: fields.map(field => field.id)
            })
        }
    }, ...fields.map(field => normalizedField(field, field.id)))
}