import {createBaseField} from "./createBaseField";
import {COL_WIDTH} from "../../../../config";
import ImageNormalizedEntry from "../../types/element/fields/ImageNormalizedEntry";

type Payload = {
    src: string
    width?: number
}

export default function createImage({src, width = COL_WIDTH}: Payload): ImageNormalizedEntry {
    const baseField = createBaseField(width);

    return {
        type: 'image',
        ...baseField,
        src,
    }
}