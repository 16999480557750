import BaseTextStyleToggle from "./BaseTextStyleToggle";
import {faBold, faItalic, faStrikethrough, faUnderline} from "@fortawesome/free-solid-svg-icons";

type Props = {
    styleType: 'text' | 'headline'
    styleId: string
}

export default function LinkStyleToggles({styleId, styleType}: Props) {
    return (
        <>
            <label className="form-label small">Link-Stil</label>
            <div className="d-flex">
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkBold" icon={faBold}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkItalic" icon={faItalic}/>
                <BaseTextStyleToggle styleType={styleType} styleId={styleId} styleProp="linkUnderline" icon={faUnderline}/>
            </div>
        </>
    );
}