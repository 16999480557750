import {configureStore} from '@reduxjs/toolkit';
import {designerReducer} from "./designerSlice";

const designerStore = configureStore({
    reducer: designerReducer,
    devTools: process.env.NODE_ENV === 'development' ? {
        name: 'designer Store',
    } : false
});

export default designerStore;

export type DesignerStore = typeof designerStore;
export type DesignerDispatch = typeof designerStore.dispatch;
export type DesignerRootState = ReturnType<typeof designerStore.getState>;