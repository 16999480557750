import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import createDesignerState from "./factories/createDesignerState";
import {DesignerModal, StyleType} from "./types/DesignerState";
import setDefaultStyleSet from "./util/setDefaultStyleSet";
import StyleSet from "./types/StyleSet";
import normalizeStyleSets from "./normalizer/normalizeStyleSets";
import arrayRemove from "../common/util/arrayRemove";
import mergeDraftState from "../common/util/mergeDraftState";
import fetchStyleSets from "./actions/fetchStyleSets";
import BaseTextStyle from "./types/styles/BaseTextStyle";
import normalizeFont from "./normalizer/normalizeFont";
import normalizeColorSet from "../colorer/normalizer/normalizeColorSet";
import normalizeFonts from "./normalizer/normalizeFonts";

const designerSlice = createSlice({
    name: 'design',
    initialState: createDesignerState(),
    reducers: {
        setColorSets: (state, {payload: styleSets}: PayloadAction<StyleSet[]>) => {
            mergeDraftState(state, normalizeStyleSets(styleSets));
        },
        addStyleSet: (state, {payload: styleSet}: PayloadAction<StyleSet>) => {
            mergeDraftState(state, normalizeStyleSets([styleSet]));

            if (styleSet.default) {
                setDefaultStyleSet(state, styleSet.id);
            }

            state.activeStyleSetId = styleSet.id;
        },
        removeStyleSet: (state, {payload: styleSetId}: PayloadAction<string>) => {
            arrayRemove(state.styleSetIds, styleSetId);

            if (state.styleSets[styleSetId]?.default) {
                setDefaultStyleSet(state, undefined);
            }
        },
        updateStyleSet: (state, {payload}: PayloadAction<{ styleId: string, values: { name?: string, default?: boolean } }>) => {
            const {styleId, values} = payload;
            const style = state.styleSets[styleId];
            if (!style) {
                return;
            }
            state.styleSets[styleId] = {...style, ...values};

            if (typeof values.default !== undefined && values.default !== style.default) {
                setDefaultStyleSet(state, values.default ? styleId : undefined);
            }
        },
        updateBaseTextStyle: (state, {payload}: PayloadAction<{ styleId: string, styleType: 'text' | 'headline', values: Partial<BaseTextStyle> }>) => {
            const {styleId, styleType, values} = payload;
            const style = state.styles[styleId];
            // @ts-ignore
            if (style?.type === styleType) {
                // @ts-ignore
                state.styles[styleId] = {...style, ...values};
            }
        },
        setActiveStyleId: (state, {payload: styleId}: PayloadAction<string | undefined>) => {
            state.activeStyleSetId = styleId;
        },
        setActiveStyleType: (state, {payload: styleType}: PayloadAction<StyleType | undefined>) => {
            state.activeStyleType = styleType || 'headline1';
        },
        openModal: (state, {payload: modal}: PayloadAction<DesignerModal>) => {
            state.modal = modal;
        },
        closeModal: (state) => {
            state.modal = undefined;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchStyleSets.fulfilled, (state, {payload}) => {
            if (!payload) return;

            const {styleSets, styleSetRefs, defaultColorSet, fonts} = payload;

            state.initialized = true;

            mergeDraftState(state, normalizeStyleSets(styleSets));
            mergeDraftState(state, normalizeFonts(fonts));
            mergeDraftState(state, normalizeColorSet(defaultColorSet));

            Object.values(styleSetRefs.colorSets).forEach(colorSet => {
                colorSet && mergeDraftState(state, normalizeColorSet(colorSet))
            });

            Object.values(styleSetRefs.fonts).forEach(font => {
                font && mergeDraftState(state, normalizeFont(font))
            });

            state.defaultColorSetId = defaultColorSet.id;
            state.defaultFontId = state.fontIds[0];

            let defined = false;
            styleSets.forEach(styleSet => {
                if (styleSet.id === state.activeStyleSetId) {
                    defined = true;
                } else if (styleSet.default) {
                    state.activeStyleSetId = styleSet.id;
                    defined = true;
                }
            });

            if (!defined) {
                delete state.activeStyleSetId;
            }
        });
    }
});

export const designerReducer = designerSlice.reducer;
export const designerActions = designerSlice.actions;