import {BuilderStore} from "../../builderStore";
import getElementsByRootState from "../element/getElementsByRootState";
import toApiPage from "../api/toApiPage";
import debounce from "debounce";
import request from "../../../common/util/request";

export default function initPageSync(store: BuilderStore, pageId: string) {
    const {getState} = store;

    let savedState = getElementsByRootState(getState());
    let savedBody = JSON.stringify(savedState);
    let running = false;

    const check = debounce(async () => {
        if (running) {
            check();
            return;
        }

        const nextState = getElementsByRootState(getState());
        const nextBody = JSON.stringify(toApiPage(nextState, pageId));

        if (savedBody === nextBody) {
            return;
        }

        running = true;
        await request('PUT', '/page/' + pageId + '.json', nextBody);

        running = false;
        savedBody = nextBody;
        savedState = nextState;
    }, 500);


    return store.subscribe(() => {
        const nextState = getElementsByRootState(getState());

        if (savedState !== nextState) {
            check();
        }
    });
}