import ColorerState from "../types/ColorerState";

export default function createColorerState(): ColorerState {
    return {
        initialized: false,
        colorSetIds: [],
        colors: {},
        colorSets: {}
    }
}
