import BuilderState from "../../types/element/BuilderState";
import ContainerNormalizedEntry from "../../types/element/ContainerNormalizedEntry";
import PageTarget from "../../types/targets/PageTarget";

export default function addContainer(elements: BuilderState, container: ContainerNormalizedEntry, target: PageTarget) {
    const page = elements.pages[target.pageId];
    const position = target.position;

    if (!page) {
        return;
    }

    // @ts-ignore
    elements.containers[container.id] = container;
    container.pageId = page.id;

    if (typeof position === "undefined" || position > page.containerIds.length) {
        page.containerIds.push(container.id);
    } else {
        page.containerIds.splice(position, 0, container.id);
    }
}