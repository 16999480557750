export default function setUrlParam(name: string, value?: string) {
    const params = new URLSearchParams(window.location.search);

    if (value === undefined) {
        params.delete(name);
    } else {
        params.set(name, value);
    }

    const toString = params.toString();
    window.history.replaceState({}, '', window.location.pathname + (toString ? '?' + toString : ''));
}
