import React from "react";
import useBuilderDispatch from "../../hooks/useBuilderDispatch";
import copyGroupAction from "../../util/element/copyGroupAction";
import {faCopy} from "@fortawesome/free-solid-svg-icons";
import CornerButton from "../../../common/components/CornerButton";

type Props = {
    groupId: string
}

function GroupCopyButton({groupId}: Props) {
    const dispatch = useBuilderDispatch();

    return (
        <CornerButton icon={faCopy} position="bottom-left" onClick={() => dispatch(copyGroupAction(groupId))}>
            Duplizieren
        </CornerButton>
    );
}

export default React.memo(GroupCopyButton);