import {FormEvent, useState} from "react";
import useColorerDispatch from "../../hooks/useColorerDispatch";
import {colorerActions} from "../../colorerSlice";
import createColor from "../../factories/createColor";
import useColorerSelector from "../../hooks/useColorerSelector";
import ColorForm from "../ColorForm";
import FormModal from "../../../common/components/FormModal";

export default function ColorNewModal() {
    const dispatch = useColorerDispatch();
    const show = useColorerSelector(state => state.modal === 'color/new');
    const colorSetId = useColorerSelector(state => state.activeColorSetId);
    const [name, setName] = useState('');
    const [hex, setHex] = useState('');
    const [description, setDescription] = useState('');

    if (!show || !colorSetId) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name || !hex) return;

        const color = createColor(name, hex, description);
        dispatch(colorerActions.addColor({colorSetId, color}));
        dispatch(colorerActions.setActiveColorId(color.id));
        cancel();
    }

    const cancel = () => {
        setName('');
        setHex('');
        setDescription('');
        dispatch(colorerActions.closeModal());
    };

    return (
        <FormModal title="Neue Farbe hinzufügen" submit={name && hex ? submit : undefined} cancel={cancel}>
            <ColorForm name={name} setName={setName}
                       hex={hex} setHex={setHex}
                       description={description} setDescription={setDescription}
                       submit={submit} id="new"
            />
        </FormModal>
    )
}