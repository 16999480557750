import React from "react";
import FieldFixture from "./FieldFixture";

type Props = {
    fixtureId: string
}

export default function DragOverlayFixture({fixtureId}: Props) {
    return (
        <div className="new">
            <FieldFixture fixtureId={fixtureId}/>
        </div>
    );
}