import {FormEvent} from "react";
import ColorPicker from "./ColorPicker";

type Props = {
    id: string
    name: string
    hex: string
    description: string
    setName: (name: string) => void
    setHex: (hex: string) => void
    setDescription: (description: string) => void
    submit: (event: FormEvent) => void
}


export default function ColorForm({id, name, hex, description, setName, setHex, setDescription, submit}: Props) {
    return (
        <form onSubmit={submit}>
            <div className="mb-3">
                <label htmlFor={id + "-name"} className="form-label">Bezeichnung</label>
                <input type="text" value={name} className="form-control" id={id + "-name"}
                       onChange={e => setName(e.target.value)} required/>
            </div>
            <div className="mb-4">
                <label htmlFor={id + "-description"} className="form-label">Verwendungszweck</label>
                <input type="text" value={description} className="form-control" id={id + "-description"}
                       onChange={e => setDescription(e.target.value)}/>
            </div>
            <div className="d-flex mt-3">
                <ColorPicker
                    color={hex}
                    onChangeComplete={c => setHex(c.hex)}
                />
            </div>
        </form>
    )
}