import BuilderState from "../../types/element/BuilderState";
import ElementTarget from "../../types/targets/ElementTarget";
import removeField from "./removeField";
import addField from "./addField";

export default function moveField(elements: BuilderState, fieldId: string, target: ElementTarget) {
    const field = elements.fields[fieldId];

    if (!field) {
        return;
    }

    removeField(elements, fieldId, true);
    addField(elements, field, target);
}