import useDesignerSelector from "../../hooks/useDesignerSelector";
import selectBaseTextStyle from "../../selectors/selectBaseTextStyle";
import FontSelectOption from "./FontSelectOption";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";

type Props = {
    styleType: "text" | "headline"
    styleId: string
}

export default function FontSelect({styleId, styleType}: Props) {
    const dispatch = useDesignerDispatch();
    const fontIds = useDesignerSelector(state => state.fontIds)
    const fontId = useDesignerSelector(state => selectBaseTextStyle(state, styleType, styleId)?.fontId);
    const onChange = (fontId: string) => {

        dispatch(designerActions.updateBaseTextStyle({
            styleId,
            styleType,
            // @ts-ignore
            values: {fontId}
        }))
    }

    return (
        <>
            <label className="form-label small">Schriftart</label>
            <select className="form-select form-select-sm border-0 ps-0" value={fontId} onChange={e => onChange(e.target.value)}>
                {fontIds.map(fontId => <FontSelectOption key={fontId} fontId={fontId}/>)}
            </select>
        </>
    );
}