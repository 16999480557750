import React from "react";
import useElementsSelector from "../../hooks/element/useElementsSelector";
import GroupDropzone from "./GroupDropzone";
import Row from "./Row";

type Props = {
    groupId: string
    editable?: boolean
}

function GroupInside({groupId, editable}: Props) {
    const rowIds = useElementsSelector(elements => elements.groups[groupId]?.rowIds || []);

    return (
        <div className="position-relative h-100">
            {editable && <GroupDropzone position={0} groupId={groupId}/>}
            {rowIds.map((rowId, index) => (
                <React.Fragment key={rowId}>
                    <Row rowId={rowId} editable={editable}/>
                    {editable && <GroupDropzone position={index + 1} groupId={groupId}/>}
                </React.Fragment>
            ))}
        </div>
    );
}

export default React.memo(GroupInside);
