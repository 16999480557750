import useBuilderSelector from "../useBuilderSelector";
import {editorActions} from "../../slices/editorSlice";
import useBuilderDispatch from "../useBuilderDispatch";
import {BuilderRootState} from "../../builderStore";
import {useCallback} from "react";

const activeSelector = (state: BuilderRootState, groupId: string) => state.undoable.present.editor.activeGroupId === groupId;

export default function useGroupActive(groupId: string): [boolean, (active: boolean) => void] {
    const dispatch = useBuilderDispatch();
    const active = useBuilderSelector(state => activeSelector(state, groupId));

    const setActive = useCallback((active: boolean) => {
        dispatch((dispatch, getState) => {
            const currentActive = activeSelector(getState(), groupId);

            if (currentActive !== active) {
                dispatch(editorActions.setActiveGroup(active ? groupId : undefined));
            }
        });
    }, [dispatch, groupId]);

    return [active, setActive];
}