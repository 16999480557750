import React from 'react';
import {Provider} from 'react-redux';
import DesignSets from "./DesignSets";
import designerStore from "../designerStore";

export default function Designer() {
    return (
        <Provider store={designerStore}>
            <DesignSets/>
        </Provider>
    );
}