import useColorerSelector from "../hooks/useColorerSelector";
import ColorListItem from "./ColorListItem";
import useColorerDispatch from "../hooks/useColorerDispatch";
import {colorerActions} from "../colorerSlice";
import getActiveColorSetId from "../util/getActiveColorSetId";
import List from "../../common/components/List";

export default function ColorList() {
    const dispatch = useColorerDispatch();
    const activeColorSetId = useColorerSelector(getActiveColorSetId);
    const colorIds = useColorerSelector(state => state.colorSets[activeColorSetId || '']?.colorIds || []);
    const readonly = useColorerSelector(state => state.colorSets[activeColorSetId || '']?.readonly || false);

    if (!activeColorSetId) {
        return null;
    }

    return (
        <List
            add={!readonly ? {
                label: 'Farbe hinzufügen',
                callback: () => dispatch(colorerActions.openModal('color/new'))
            } : undefined}
        >
            {colorIds.map((colorId, i) => <ColorListItem colorId={colorId} key={colorId} position={i + 1}/>)}
        </List>
    );
}