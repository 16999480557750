import GroupNormalizedEntry from "../types/element/GroupNormalizedEntry";
import {COL_WIDTH} from "../../../config";
import {v4 as uuid} from "uuid";

export default function createGroupNormalized(width: number = COL_WIDTH): GroupNormalizedEntry {
    return {
        id: uuid(),
        width,
        rowIds: []
    }
}