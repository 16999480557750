import React from "react";
import styles from "./Button.module.scss";
import classNames from "classnames";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

type Props = {
    size?: 'normal' | 'lg' | 'sm'
    loading?: boolean
} & React.ComponentProps<"button">

export default function Button(
    {className, children, size = 'normal', loading, ...rest}: Props
) {
    return (
        <button className={classNames(
            className,
            styles.button,
            size && styles['size-' + size],
        )} {...rest}>
            {children}
            {loading && <span className="ps-2"><FontAwesomeIcon spin icon={faSpinner}/></span>}
        </button>
    );
}