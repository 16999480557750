import DragSource from "../../types/drag/DragSource";
import getElementsByRootState from "../element/getElementsByRootState";
import {dragActions} from "../../slices/dragSlice";
import {builderActions} from "../../slices/builderSlice";
import {DragStartEvent} from "@dnd-kit/core";
import {BuilderDispatch, BuilderRootState} from "../../builderStore";

export default function onDragStartAction({active}: DragStartEvent) {
    return (dispatch: BuilderDispatch, getState: () => BuilderRootState) => {
        const source = active.data.current as DragSource | undefined;
        const elements = getElementsByRootState(getState());

        if (!source) {
            return;
        }

        switch (source.type) {
            case "fixture":
                dispatch(dragActions.start({source}));
                break;
            case "field":
                dispatch(dragActions.start({elements, source}));

                setTimeout(() => {
                    dispatch(builderActions.removeField({fieldId: source.fieldId, silent: true, keepReference: true}));
                })

                break;
            case "group":
                dispatch(dragActions.start({elements, source}));

                setTimeout(() => {
                    dispatch(builderActions.removeGroup({groupId: source.groupId, silent: true, keepReference: true}));
                })

                break;
        }
    }
}