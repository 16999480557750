import createGroupCopy from "./createGroupCopy";
import {BuilderDispatch, BuilderRootState} from "../../builderStore";
import PageTarget from "../../types/targets/PageTarget";
import getElementsByRootState from "./getElementsByRootState";
import {builderActions} from "../../slices/builderSlice";
import {editorActions} from "../../slices/editorSlice";

export default function copyGroupAction(groupId: string) {
    return (dispatch: BuilderDispatch, getState: () => BuilderRootState) => {
        const elements = getElementsByRootState(getState());

        const group = elements.groups[groupId];
        const container = elements.containers[group?.containerId || ''];
        const page = elements.pages[container?.pageId || ''];

        const [groupCopy, references] = createGroupCopy(elements, groupId);

        if (!groupCopy || !page || !container) {
            return;
        }

        const target: PageTarget = {
            type: 'page',
            pageId: page.id,
            position: page.containerIds.indexOf(container.id) + 1,
        };

        dispatch(builderActions.addGroup({group: groupCopy, target, references}));
        dispatch(editorActions.setActiveGroup(groupCopy.id))
    };
}