import {DragEndEvent} from "@dnd-kit/core";
import getDropTarget from "./getDropTarget";
import {dragActions} from "../../slices/dragSlice";
import {builderActions} from "../../slices/builderSlice";
import getFixture from "../element/getFixture";
import {editorActions} from "../../slices/editorSlice";
import {BuilderDispatch, BuilderRootState} from "../../builderStore";

export default function onDragEndAction({over}: DragEndEvent) {
    return (dispatch: BuilderDispatch, getState: () => BuilderRootState) => {
        const {elements, source} = getState().drag;
        const target = getDropTarget(over);

        dispatch(dragActions.end());
        elements && dispatch(builderActions.revert({state: elements, silent: true}));

        if (!source || !target) {
            return;
        }

        switch (source.type) {
            case "fixture":
                const fixture = getFixture(source.fixtureId);

                if (!fixture) {
                    return;
                }

                const field = fixture.factory();

                switch (target.type) {
                    case "page":
                        //const container = createContainer([createGroup([createRow([field])])])
                        //dispatch(builderActions.addContainer({container, target}))
                        break;
                    case "container":
                        //const group =  createGroup([createRow([field]);
                        //dispatch(builderActions.addGroup({group, target}))
                        break;
                    case "group":
                        //const row =  createRow([field]);
                        //dispatch(builderActions.addRow({row, target}))
                        break;
                    case "row":
                        //dispatch(builderActions.addRow({field, target}))
                        break;
                }

                dispatch(builderActions.addField({field, target}));
                dispatch(editorActions.setActiveField(field.id));

                break;
            case "field":


                dispatch(builderActions.moveField({fieldId: source.fieldId, target}));
                break;
            case "group":
                if (target.type !== 'page' && target.type !== 'container') {
                    return;
                }

                dispatch(builderActions.moveGroup({groupId: source.groupId, target}));
                break;
        }
    }
}