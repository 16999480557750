import {FormEvent, useEffect, useState} from "react";
import useDesignerDispatch from "../../hooks/useDesignerDispatch";
import {designerActions} from "../../designerSlice";
import useDesignerSelector from "../../hooks/useDesignerSelector";
import StyleSetForm from "../StyleSetForm";
import getActiveStyleSetId from "../../util/getActiveStyleSetId";
import FormModal from "../../../common/components/FormModal";

export default function DesignEditModal() {
    const dispatch = useDesignerDispatch();
    const activeStyleId = useDesignerSelector(getActiveStyleSetId);
    const show = useDesignerSelector(state => state.modal === 'style/edit');
    const style = useDesignerSelector(state => state.styleSets[activeStyleId || '']);
    const [name, setName] = useState('');
    const [isDefault, setDefault] = useState<boolean>(false);

    useEffect(() => {
        setName(style?.name || '');
        setDefault(!!style?.default);
    }, [style, setName, setDefault]);

    if (!show || !style || style.readonly) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name) return;

        dispatch(designerActions.updateStyleSet({styleId: style.id, values: {name, default: isDefault}}));
        cancel();
    }

    const cancel = () => {
        dispatch(designerActions.closeModal());
    };

    return (
        <FormModal title="Design bearbeiten" cancel={cancel} submit={submit}>
            <StyleSetForm name={name} setName={setName}
                          default={isDefault} setDefault={setDefault}
                          submit={submit}/>
        </FormModal>
    )
}