import useColorerSelector from "../hooks/useColorerSelector";
import ColorSetListItem from "./ColorSetListItem";
import useColorerDispatch from "../hooks/useColorerDispatch";
import {colorerActions} from "../colorerSlice";
import List from "../../common/components/List";

export default function ColorSetList() {
    const dispatch = useColorerDispatch();
    const colorSetIds = useColorerSelector(state => state.colorSetIds);

    return (
        <List
            withDefault
            add={{
                label: 'Set hinzufügen',
                callback: () => dispatch(colorerActions.openModal('color-set/new'))
            }}
        >
            {colorSetIds.map(colorSetId => <ColorSetListItem colorSetId={colorSetId} key={colorSetId}/>)}
        </List>
    );
}