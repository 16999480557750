import BuilderState from "../../types/element/BuilderState";
import useBuilderSelector from "../useBuilderSelector";
import getElementsByRootState from "../../util/element/getElementsByRootState";
import {createSelector} from "@reduxjs/toolkit";
import {BuilderRootState} from "../../builderStore";

export default function useElementsSelector<TSelected>(selector: (elements: BuilderState) => TSelected, equalityFn?: (left: TSelected, right: TSelected) => boolean): TSelected {
    const reselect = createSelector(
        (state: BuilderRootState) => getElementsByRootState(state),
        selector,
    );

    return useBuilderSelector(reselect, equalityFn);
}