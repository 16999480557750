import {FormEvent, useEffect, useState} from "react";
import useColorerDispatch from "../../hooks/useColorerDispatch";
import {colorerActions} from "../../colorerSlice";
import useColorerSelector from "../../hooks/useColorerSelector";
import ColorSetForm from "../ColorSetForm";
import getActiveColorSetId from "../../util/getActiveColorSetId";
import FormModal from "../../../common/components/FormModal";

export default function ColorSetEditModal() {
    const dispatch = useColorerDispatch();
    const activeColorSetId = useColorerSelector(getActiveColorSetId);
    const show = useColorerSelector(state => state.modal === 'color-set/edit');
    const colorSet = useColorerSelector(state => state.colorSets[activeColorSetId || '']);
    const [name, setName] = useState('');
    const [isDefault, setDefault] = useState<boolean>(false);

    useEffect(() => {
        setName(colorSet?.name || '');
        setDefault(!!colorSet?.default);
    }, [colorSet, setName, setDefault]);

    if (!show || !colorSet || colorSet.readonly) return null;

    const submit = (e?: FormEvent) => {
        if (e) e.preventDefault();
        if (!name) return;

        dispatch(colorerActions.updateColorSet({colorSetId: colorSet.id, values: {name, default: isDefault}}));
        cancel();
    }

    const cancel = () => {
        dispatch(colorerActions.closeModal());
    };

    return (
        <FormModal title=" Set bearbeiten" cancel={cancel} submit={name ? submit : undefined}>
            <ColorSetForm name={name} setName={setName}
                          default={isDefault} setDefault={setDefault}
                          submit={submit}/>
        </FormModal>
    )
}