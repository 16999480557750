import classNames from "classnames";
import styles from "./NumberSelect.module.scss";

type Props = {
    value: number
    onChange: (value: number) => void,
    min: number,
    max: number,
    className?: string
    step?: number
}

export default function NumberSelect({value, onChange, min, max, className, step = 1}: Props) {
    const steps = Math.round((max - min) / step + 1);

    return (
        <select className={classNames("form-select", styles.select, className)}
                value={value} onChange={e => onChange(Number(e.target.value))}>
            {Array.from(Array(steps).keys()).map(i => {
                const value = Math.round((i * step + min) * 100) / 100;
                return (
                    <option key={i} value={value}>{value}</option>
                )
            })}
        </select>
    )
}